.insights-feed {

    &__card {
        border-radius: 1rem;
        box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        overflow: hidden;

        &-body {            
            display: flex;
                flex-direction: column;
            height: 100%;
            padding: $spacing;
            width: 50%;
        }
        
        &:active,
        &:focus,
        &:hover {
            .insights-feed__card-body {
                background-color: $whiteDark;
            }
            
            .insights-feed__link::after {
                transform: translate(.5em, -50%);
            }
        }
    }

    &__excerpt {
        margin-bottom: $spacing;
    }

    &__featured-image {
        height: 100%;
        position: relative;
        
        img {
            @include object-fit(cover, center);
        }
    
        &.placeholder {
            background: url('/images/site-logo.svg') no-repeat center;
                background-size: 75%;
            background-color: $grayLighter;
        }
    }

    &__header {
        display: flex;
            align-items: center;
            justify-content: space-between;
        margin-bottom: $spacing;
        position: relative;
        width: 100%;

        a.arrow-link {
            text-align: end;
            text-decoration: underline;
            text-transform: none;
        }
    }

    &__heading {
        h2 {
            color: #000;
            margin-bottom: 0;
        }
    }
    
    &__insights {

        .insight-1,
        .insight-2 {
            .insights-feed__card-header {
                img {
                    border-start-start-radius: 1rem;
                    border-end-start-radius: 1rem;
                }
            }
        }
    }

    &__link {
        margin-top: auto;
    }

    &__title {
        h3 {
            color: $blue;
        }
    }

    @include media($screen-sm) {
        &__insights {
            display: grid;
                gap: $spacing;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);

            .insight-1 {
                grid-area: 1 / 1 / 2 / 3;
            }

            .insight-2 {
                grid-area: 2 / 1 / 3 / 3;
            }

            .insight-1,
            .insight-2 {
                .insights-feed__card-body {
                    width: 50%;
                }
                
                .insights-feed__card-header {
                    height: 100%;
                    width: 50%;
                }
            }

            .insight-3 {
                flex-direction: column;
                grid-area: 1 / 3 / 3 / 4;

                .insights-feed__card-header {
                    height: 50%;
                    min-height: 40%;
                    width: 100%;

                    img {
                        border-start-start-radius: 1rem;
                        border-start-end-radius: 1rem;
                    }
                }

                .insights-feed__card-body {
                    width: 100%;
                }
                
                .insights-feed__title {
                    h3 {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    @include media($screen-sm-max, 'max') {
        &__card:not(:last-child) {
            margin-bottom: $spacing;
        }

        &__insights {
            .insights-feed__card-header {
                width: 50%;
            }
        }
    }
}