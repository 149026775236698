/**
 * DBS Slate Main Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will be loaded on pages that aren't the front page or blog.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Border Radius
 */
/**
 * Generate transition delay based on nth-child
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.brand-strip__heading {
  max-width: 47rem;
  opacity: 0;
  transition: 0.75s opacity;
  text-align: center; }
  .brand-strip__heading.in-view {
    opacity: 1; }

.brand-strip__heading {
  margin: 0 auto 1.5rem; }

.brand-strip__strip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 1.5rem; }
  @media screen and (max-width: 61.999em) {
    .brand-strip__strip {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.brand-strip__image {
  max-width: 15rem;
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  transition: 0.75s opacity;
  padding: 1rem; }
  .brand-strip__image:nth-child(2) {
    transition-delay: 0.25s; }
  .brand-strip__image:nth-child(3) {
    transition-delay: 0.5s; }
  .brand-strip__image:nth-child(4) {
    transition-delay: 0.75s; }
  .brand-strip__image:nth-child(5) {
    transition-delay: 1s; }
  .brand-strip__image:nth-child(6) {
    transition-delay: 1.25s; }
  .brand-strip__image:nth-child(7) {
    transition-delay: 1.5s; }
  .brand-strip__image:nth-child(8) {
    transition-delay: 1.75s; }
  .brand-strip__image.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .brand-strip__image img {
    max-height: 3rem;
    width: auto; }
  @media screen and (min-width: 48em) {
    .brand-strip__image img {
      max-height: 4rem; } }

.brand-strip.align-left .brand-strip__heading {
  margin: 0;
  max-width: none;
  text-align: start; }

.brand-strip.align-left .brand-strip__strip {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.cards .card:nth-child(1) {
  transition-delay: 0s; }

.cards .card:nth-child(2) {
  transition-delay: 0.25s; }

.cards .card:nth-child(3) {
  transition-delay: 0.5s; }

.cards .card:nth-child(4) {
  transition-delay: 0.75s; }

.cards .card:nth-child(5) {
  transition-delay: 1s; }

.cards .card:nth-child(6) {
  transition-delay: 1.25s; }

.cards .card:nth-child(7) {
  transition-delay: 1.5s; }

.cards .card:nth-child(8) {
  transition-delay: 1.75s; }

.cards .card:nth-child(9) {
  transition-delay: 2s; }

.cards .card:nth-child(10) {
  transition-delay: 2.25s; }

.cards .card:nth-child(11) {
  transition-delay: 2.5s; }

.cards .card:nth-child(12) {
  transition-delay: 2.75s; }

.cards .card:nth-child(13) {
  transition-delay: 3s; }

.cards .card:nth-child(14) {
  transition-delay: 3.25s; }

.cards .card:nth-child(15) {
  transition-delay: 3.5s; }

.cards .card:nth-child(16) {
  transition-delay: 3.75s; }

.cards .card:nth-child(17) {
  transition-delay: 4s; }

.cards .card:nth-child(18) {
  transition-delay: 4.25s; }

.cards .card:nth-child(19) {
  transition-delay: 4.5s; }

.cards .card:nth-child(20) {
  transition-delay: 4.75s; }

.cards .animation-trigger.in-view ~ .card {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.cards .contain {
  gap: 1.5rem; }

.cards .card {
  background: #ffffff;
  opacity: 0;
  padding: 1rem;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .cards .card__icon {
    height: 0;
    margin-bottom: 1rem;
    max-width: 8rem;
    padding-bottom: 4rem;
    position: relative; }
    .cards .card__icon img,
    .cards .card__icon svg {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .cards .card__icon img,
        .cards .card__icon svg {
          height: 100%;
          object-fit: contain;
          object-position: left; } }
    .cards .card__icon svg path {
      transition: fill .25s; }
  .cards .card__text {
    color: #404144;
    margin-bottom: 1rem; }
    .cards .card__text h1, .cards .card__text h2, .cards .card__text h3, .cards .card__text h4, .cards .card__text h5, .cards .card__text h6,
    .cards .card__text .h1, .cards .card__text .h2, .cards .card__text .h3, .cards .card__text .h4, .cards .card__text .h5, .cards .card__text .h6 {
      color: #11538B;
      font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
      font-size: 1.125rem;
      margin-bottom: .75rem; }
  .cards .card__link {
    height: 2rem;
    width: 2rem;
    background: url("/icons/arrow-right.svg") no-repeat left;
    background-size: contain;
    display: block;
    transition: transform .25s; }
  .cards .card.linkclicker:hover svg path {
    fill: #F26525; }
  .cards .card.linkclicker:hover .card__link {
    transform: translate3d(0.5rem, 0, 0); }

.cards.numbers .card {
  padding-top: 3.5rem;
  position: relative; }
  .cards.numbers .card::before {
    color: #BAD2E5;
    font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
    font-size: 8rem;
    line-height: 1;
    opacity: .3;
    position: absolute;
    top: 1rem;
    left: 4.5rem; }
  .cards.numbers .card:nth-child(2)::before {
    content: '1'; }
  .cards.numbers .card:nth-child(3)::before {
    content: '2'; }
  .cards.numbers .card:nth-child(4)::before {
    content: '3'; }
  .cards.numbers .card:nth-child(5)::before {
    content: '4'; }
  .cards.numbers .card:nth-child(6)::before {
    content: '5'; }
  .cards.numbers .card:nth-child(7)::before {
    content: '6'; }
  .cards.numbers .card:nth-child(8)::before {
    content: '7'; }
  .cards.numbers .card:nth-child(9)::before {
    content: '8'; }
  .cards.numbers .card:nth-child(10)::before {
    content: '9'; }
  .cards.numbers .card:nth-child(11)::before {
    content: '10'; }
  .cards.numbers .card:nth-child(12)::before {
    content: '11'; }
  .cards.numbers .card:nth-child(13)::before {
    content: '12'; }

@media screen and (min-width: 48em) {
  .cards {
    -ms-flex-align: start;
    align-items: start; }
    .cards .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1;
      flex: 1; }
    .cards .card__text--wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%; }
      .cards .card__text--wrapper .card__link {
        margin-top: auto; }
    .cards .contain.cards-4 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(25% - 1.5rem); }
    .cards .contain.cards-5 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(20% - 1.5rem); }
    .cards .contain.cards-3 .card, .cards .contain.cards-6 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(33.33% - 1.5rem); } }

.careers-feed {
  padding-bottom: 3rem;
  padding-top: 3rem;
  background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0) 100%), #F1F2F2; }
  @media screen and (min-width: 48em) {
    .careers-feed {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .careers-feed__text {
    margin-bottom: 3rem;
    opacity: 0;
    transition: opacity 0.75s; }
    .careers-feed__text.in-view {
      opacity: 1; }
  .careers-feed__block {
    background: #ffffff;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1rem;
    width: 100%; }
    .careers-feed__block .h6 {
      font-size: 1.125rem; }
    .careers-feed__block .arrow-link {
      padding-right: 1.5rem; }
  .careers-feed__list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .careers-feed__list--item {
      border-bottom: 1px solid #dadada;
      padding: 1.5rem 1rem 1.5rem 1rem;
      position: relative; }
      .careers-feed__list--item:last-child {
        border-bottom: none; }
      .careers-feed__list--item .h6 {
        margin-bottom: .75rem; }
      .careers-feed__list--item .arrow-link {
        text-transform: none; }
  .careers-feed__blocks {
    box-shadow: none !important; }
  .careers-feed .flickity-button:disabled {
    display: none; }
  .careers-feed .flickity-button.previous {
    left: -1.5rem; }
  .careers-feed .flickity-button.next {
    right: -1.5rem; }
  .careers-feed .flickity-button svg path {
    fill: #11538B; }
  @media screen and (min-width: 48em) {
    .careers-feed__text {
      padding-right: 3rem;
      width: 40%; }
    .careers-feed__blocks {
      gap: 1.5rem; }
    .careers-feed__block {
      margin-right: 1.5rem;
      margin-bottom: 0;
      width: 33.33%; }
      .careers-feed__block .arrow-link {
        margin-top: auto; }
      .careers-feed__block h3 {
        min-height: 2.75rem; }
    .careers-feed__list--item {
      padding-left: 3rem; }
      .careers-feed__list--item::before {
        border-top: 4px solid #F26525;
        content: '';
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 1rem; }
      .careers-feed__list--item .h6 {
        margin-bottom: 0; } }
  @media screen and (min-width: 62em) {
    .careers-feed__text {
      margin-bottom: 0;
      padding-right: 2rem;
      width: 35%; }
    .careers-feed__blocks {
      gap: 1.5rem;
      width: 65%; }
    .careers-feed__list {
      width: 60%; } }
  .careers-feed.list-view {
    background: #ffffff;
    padding-bottom: 0;
    padding-top: 0; }

.case-studies-feed .case-studies-feed__card:nth-child(1) {
  transition-delay: 0s; }

.case-studies-feed .case-studies-feed__card:nth-child(2) {
  transition-delay: 0.25s; }

.case-studies-feed .case-studies-feed__card:nth-child(3) {
  transition-delay: 0.5s; }

.case-studies-feed .case-studies-feed__card:nth-child(4) {
  transition-delay: 0.75s; }

.case-studies-feed .case-studies-feed__card:nth-child(5) {
  transition-delay: 1s; }

.case-studies-feed .case-studies-feed__card:nth-child(6) {
  transition-delay: 1.25s; }

.case-studies-feed .case-studies-feed__card:nth-child(7) {
  transition-delay: 1.5s; }

.case-studies-feed .case-studies-feed__card:nth-child(8) {
  transition-delay: 1.75s; }

.case-studies-feed .case-studies-feed__card:nth-child(9) {
  transition-delay: 2s; }

.case-studies-feed .case-studies-feed__card:nth-child(10) {
  transition-delay: 2.25s; }

.case-studies-feed .case-studies-feed__card:nth-child(11) {
  transition-delay: 2.5s; }

.case-studies-feed .case-studies-feed__card:nth-child(12) {
  transition-delay: 2.75s; }

.case-studies-feed .case-studies-feed__card:nth-child(13) {
  transition-delay: 3s; }

.case-studies-feed .case-studies-feed__card:nth-child(14) {
  transition-delay: 3.25s; }

.case-studies-feed .case-studies-feed__card:nth-child(15) {
  transition-delay: 3.5s; }

.case-studies-feed .case-studies-feed__card:nth-child(16) {
  transition-delay: 3.75s; }

.case-studies-feed .case-studies-feed__card:nth-child(17) {
  transition-delay: 4s; }

.case-studies-feed .case-studies-feed__card:nth-child(18) {
  transition-delay: 4.25s; }

.case-studies-feed .case-studies-feed__card:nth-child(19) {
  transition-delay: 4.5s; }

.case-studies-feed .case-studies-feed__card:nth-child(20) {
  transition-delay: 4.75s; }

.case-studies-feed .animation-trigger.in-view ~ .case-studies-feed__studies > .case-studies-feed__card,
.case-studies-feed .animation-trigger.in-view ~ .case-studies-feed__heading {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.case-studies-feed__card {
  border-radius: 1rem;
  box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .case-studies-feed__card-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem; }
    .case-studies-feed__card-body:active, .case-studies-feed__card-body:focus, .case-studies-feed__card-body:hover {
      background-color: #fbfbff;
      /*                 border-end-start-radius: 1rem;
                border-end-end-radius: 1rem; */ }
  .case-studies-feed__card-header {
    background: linear-gradient(12deg, rgba(52, 100, 146, 0.34) 8.6%, rgba(9, 20, 59, 0.34) 81.82%), #33628F;
    /*             border-start-start-radius: 1rem;
            border-start-end-radius: 1rem; */
    height: 6.66rem;
    padding: 1.5rem;
    position: relative;
    text-align: center; }
    .case-studies-feed__card-header h4 {
      color: #061B3C;
      margin-bottom: 0;
      opacity: 0.16; }
    .case-studies-feed__card-header svg {
      height: 4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto; }

.case-studies-feed__excerpt {
  font-size: 0.875rem;
  margin-bottom: 1.5rem; }

.case-studies-feed__heading {
  margin-bottom: 1.5rem;
  opacity: 0;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }

.case-studies-feed__link {
  margin-top: auto; }

.case-studies-feed__title h3 {
  color: #11538B; }

@media screen and (min-width: 48em) {
  .case-studies-feed__card {
    width: calc(33.33% - 1.5rem); }
  .case-studies-feed__studies {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 2.25rem;
    -ms-flex-pack: center;
    justify-content: center; } }

@media screen and (min-width: 62em) {
  .case-studies-feed__card-body {
    height: 100%; }
  .case-studies-feed__card-header {
    height: 7.58rem; } }

@media screen and (max-width: 47.999em) {
  .case-studies-feed__card:not(:last-child) {
    margin-bottom: 1.5rem; } }

.contact-cta__wrapper {
  background: linear-gradient(to right, #061B3C, #11538B);
  opacity: 0;
  padding: 1.5rem;
  position: relative;
  transition: opacity 0.75s; }
  .contact-cta__wrapper.in-view {
    opacity: 1; }

.contact-cta p {
  margin: 0 auto 2rem;
  max-width: 45rem; }

@media screen and (min-width: 48em) {
  .contact-cta__wrapper {
    padding: 3rem; } }

@media screen and (min-width: 62em) {
  .contact-cta__wrapper {
    padding: 4.5rem;
    padding-right: 9rem; }
  .contact-cta .button {
    font-size: 1.125rem;
    padding: 1rem 3rem; } }

/**
 *	Styles for the sidebar layout
 *
 *	This stylesheet properties only for layout-content_with_sidebar
 *		blog page has it's own stylesheet for sidebar
 *	[-If you want to make it global you can do it so-]
 */
.content-with-sidebar .contain {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media screen and (min-width: 75em) {
    .content-with-sidebar .contain {
      -ms-flex-direction: row;
      flex-direction: row; } }

@media screen and (min-width: 75em) {
  .content-with-sidebar .content {
    width: 70%; } }

.content-with-sidebar .content > .layout:first-child {
  margin-top: 0rem; }

.content-with-sidebar .content .layout:last-child {
  margin-bottom: 0; }

.content-with-sidebar .content .layout .contain, .content-with-sidebar .content .layout.contain {
  padding-left: 0;
  padding-right: 0; }

.content-with-sidebar .content .half-and-half__left.bg-img {
  margin-left: 0;
  width: 50%; }

.content-with-sidebar .content .half-and-half__right.bg-img {
  margin-right: 0;
  width: 50%; }

@media screen and (min-width: 75em) {
  .content-with-sidebar .sidebar {
    padding-right: 3rem;
    width: 30%; } }

.content-with-sidebar.sidebar-right .sidebar {
  -ms-flex-order: 99;
  order: 99;
  padding-right: 0;
  padding-left: 3rem; }

/**
 * Full Width Flex Layout
 * DBS>Interactive
 *
 * This layout takes up the full width of the browser and may have a background image.
 */
.full-width .contain > * {
  opacity: 0;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }

.full-width .animation-trigger.in-view ~ * {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.full-width .animation-trigger {
  position: absolute !important; }

.home-hero {
  background: linear-gradient(289deg, #010125 4.1%, #082C4B 119.86%);
  overflow: hidden;
  position: relative; }
  .home-hero__text, .home-hero__graphic {
    z-index: 3; }
  .home-hero__text {
    animation: opacity 1.5s both ease-in;
    padding: 3rem 0;
    position: relative;
    z-index: 2000; }
  .home-hero__graphic {
    height: 100%;
    mix-blend-mode: luminosity;
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translateY(-50%) rotate(180deg);
    width: 150%; }
    .home-hero__graphic svg {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .home-hero__graphic svg {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .home-hero__graphic.rotate canvas {
      animation: rotate 60s linear infinite forwards;
      transform-origin: center; }
  .home-hero .home-hero__line {
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0; }
    .home-hero .home-hero__line::before {
      left: 0; }
    .home-hero .home-hero__line::after {
      right: 0; }
    .home-hero .home-hero__line.line-1 {
      background: rgba(40, 22, 111, 0.1);
      left: 5vw;
      width: 34%; }
    .home-hero .home-hero__line.line-2 {
      background: linear-gradient(90deg, #09143C 0%, rgba(5, 20, 54, 0) 100%);
      left: calc(34% + 5vw);
      width: calc(66% - 10vw); }
      .home-hero .home-hero__line.line-2::before {
        left: 50%; }
    @media screen and (max-width: 47.999em) {
      .home-hero .home-hero__line.line-1 {
        width: 80%; }
      .home-hero .home-hero__line.line-2 {
        display: none; } }
  @media screen and (min-width: 48em) {
    .home-hero__text {
      padding: 7.5rem 0;
      width: 70%; }
    .home-hero__graphic {
      left: auto;
      right: -3rem;
      width: 50%; } }
  @media screen and (max-width: 61.999em) {
    .home-hero__graphic {
      left: auto;
      right: -30%;
      width: 8%; }
      .home-hero__graphic canvas {
        height: 100% !important;
        object-fit: contain;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important; }
      .home-hero__graphic:not(.rotate) canvas {
        object-fit: cover;
        object-position: right; } }
  @media screen and (min-width: 62em) {
    .home-hero__text {
      padding-right: 3rem;
      width: 45%; }
    .home-hero__graphic {
      left: -5%;
      width: 105%; } }

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 1, 0deg); }
  100% {
    transform: rotate3d(0, 0, 1, -360deg); } }

.insights-feed__card {
  border-radius: 1rem;
  box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  overflow: hidden; }
  .insights-feed__card-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    width: 50%; }
  .insights-feed__card:active .insights-feed__card-body, .insights-feed__card:focus .insights-feed__card-body, .insights-feed__card:hover .insights-feed__card-body {
    background-color: #fbfbff; }
  .insights-feed__card:active .insights-feed__link::after, .insights-feed__card:focus .insights-feed__link::after, .insights-feed__card:hover .insights-feed__link::after {
    transform: translate(0.5em, -50%); }

.insights-feed__excerpt {
  margin-bottom: 1.5rem; }

.insights-feed__featured-image {
  height: 100%;
  position: relative; }
  .insights-feed__featured-image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .insights-feed__featured-image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }
  .insights-feed__featured-image.placeholder {
    background: url("/images/site-logo.svg") no-repeat center;
    background-size: 75%;
    background-color: #F1F2F2; }

.insights-feed__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%; }
  .insights-feed__header a.arrow-link {
    text-align: end;
    text-decoration: underline;
    text-transform: none; }

.insights-feed__heading h2 {
  color: #000;
  margin-bottom: 0; }

.insights-feed__insights .insight-1 .insights-feed__card-header img,
.insights-feed__insights .insight-2 .insights-feed__card-header img {
  border-start-start-radius: 1rem;
  border-end-start-radius: 1rem; }

.insights-feed__link {
  margin-top: auto; }

.insights-feed__title h3 {
  color: #11538B; }

@media screen and (min-width: 48em) {
  .insights-feed__insights {
    display: -ms-grid;
    display: grid;
    gap: 1.5rem;
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: (1fr)[2];
    grid-template-rows: repeat(2, 1fr); }
    .insights-feed__insights .insight-1 {
      grid-area: 1 / 1 / 2 / 3; }
    .insights-feed__insights .insight-2 {
      grid-area: 2 / 1 / 3 / 3; }
    .insights-feed__insights .insight-1 .insights-feed__card-body,
    .insights-feed__insights .insight-2 .insights-feed__card-body {
      width: 50%; }
    .insights-feed__insights .insight-1 .insights-feed__card-header,
    .insights-feed__insights .insight-2 .insights-feed__card-header {
      height: 100%;
      width: 50%; }
    .insights-feed__insights .insight-3 {
      -ms-flex-direction: column;
      flex-direction: column;
      grid-area: 1 / 3 / 3 / 4; }
      .insights-feed__insights .insight-3 .insights-feed__card-header {
        height: 50%;
        min-height: 40%;
        width: 100%; }
        .insights-feed__insights .insight-3 .insights-feed__card-header img {
          border-start-start-radius: 1rem;
          border-start-end-radius: 1rem; }
      .insights-feed__insights .insight-3 .insights-feed__card-body {
        width: 100%; }
      .insights-feed__insights .insight-3 .insights-feed__title h3 {
        font-size: 2rem; } }

@media screen and (max-width: 47.999em) {
  .insights-feed__card:not(:last-child) {
    margin-bottom: 1.5rem; }
  .insights-feed__insights .insights-feed__card-header {
    width: 50%; } }

.leadership-team .member-modal {
  background: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000; }
  .leadership-team .member-modal__wrapper {
    background-color: white;
    border-radius: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 3rem);
    max-width: 55rem;
    padding: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3rem);
    z-index: 2005; }
  .leadership-team .member-modal__photo {
    background-color: #2d2d2d;
    border: 3px solid #11538B;
    border-radius: 50%;
    height: 0;
    margin-bottom: 1.5rem;
    margin-top: -8rem;
    min-height: 8rem;
    min-width: 8rem;
    overflow: hidden;
    padding-bottom: 31%;
    position: relative;
    transform: translate(0, -3rem);
    transition: transform .5s ease-in;
    width: 15.5rem; }
    .leadership-team .member-modal__photo img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .leadership-team .member-modal__photo img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .leadership-team .member-modal__photo .leadership-team__placeholder {
      background-color: #2d2d2d; }
  .leadership-team .member-modal__info {
    transform: translate(3rem, 0);
    transition: transform .5s ease-in; }
    .leadership-team .member-modal__info .title {
      background-color: #F1F2F2;
      border-radius: .5rem;
      color: #082C4B;
      font-weight: 400;
      min-height: 0 !important;
      padding: .25rem .5rem; }
  .leadership-team .member-modal__header {
    border-bottom: 1px solid #dadada;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem; }
  .leadership-team .member-modal__close {
    height: 3rem;
    width: 3rem;
    appearance: none;
    background: url("/icons/src/close-symbol-white.svg") no-repeat right center;
    background-size: 2rem;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0; }
  .leadership-team .member-modal.open {
    display: block; }
    .leadership-team .member-modal.open .member-modal__info {
      transform: translate(0, 0); }
    .leadership-team .member-modal.open .member-modal__photo {
      transform: translate(0, 0); }
  .leadership-team .member-modal .title {
    color: #404040; }

.leadership-team__bio {
  display: none; }

.leadership-team__card {
  border: 1px solid #dadada;
  border-radius: 1rem;
  cursor: pointer;
  padding: 1.5rem 3rem;
  text-align: center;
  transition: background-color .2s ease; }
  .leadership-team__card .arrow-link {
    text-transform: none; }
  .leadership-team__card .name {
    color: #11538B;
    margin-bottom: 0; }
  .leadership-team__card .position {
    font-weight: 400; }
  .leadership-team__card:active, .leadership-team__card:focus, .leadership-team__card:hover {
    background-color: #fbfbff; }
    .leadership-team__card:active .arrow-link::after, .leadership-team__card:focus .arrow-link::after, .leadership-team__card:hover .arrow-link::after {
      transform: translate(0.5em, -50%); }

.leadership-team__modal {
  display: none;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; }
  .leadership-team__modal-inner {
    background-color: #fff;
    border-radius: 1rem;
    width: 50%;
    position: relative;
    z-index: 1; }
  .leadership-team__modal::after {
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    background: rgba(0, 0, 0, 0.42);
    content: '';
    position: absolute;
    top: -800%;
    left: -50%;
    height: 100vw;
    width: 100vw;
    z-index: 0; }

.leadership-team__photo {
  height: 10rem;
  margin: 0 auto 1.5rem;
  position: relative;
  width: 10rem; }
  .leadership-team__photo img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    filter: grayscale(1); }
    @supports (object-fit: contain) {
      .leadership-team__photo img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.leadership-team__placeholder {
  background-color: #ffffff;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%; }

.leadership-team__tier-1, .leadership-team__tier-2 {
  width: 100%; }

.leadership-team__tier-2 {
  margin-top: 1.5rem; }

@media screen and (min-width: 48em) {
  .leadership-team .member-modal__wrapper {
    padding: 3rem; }
  .leadership-team .member-modal__photo {
    margin-bottom: 0;
    margin-right: 3rem; }
  .leadership-team .member-modal__body {
    width: 60%; }
  .leadership-team .member-modal .name {
    margin-bottom: .5rem; }
  .leadership-team .member-modal .title {
    min-height: 2.5rem; }
  .leadership-team__card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
    .leadership-team__card .arrow-link {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      margin-top: auto; }
  .leadership-team__photo {
    border: 3px solid #11538B;
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    overflow: hidden; }
  .leadership-team__tier-1, .leadership-team__tier-2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem; }
  .leadership-team__tier-1 .leadership-team__card {
    min-height: 28rem;
    width: calc(100% / 4 - 1rem); }
  .leadership-team__tier-2 .leadership-team__card {
    min-height: 20rem;
    padding: 1.5rem;
    width: calc(100% / 4 - 1.125rem); }
  .leadership-team__tier-2 .leadership-team__photo {
    height: 7rem;
    width: 7rem; } }

@media screen and (min-width: 62em) {
  .leadership-team__tier-1 .leadership-team__card {
    padding: 1.5rem 3rem; }
  .leadership-team__tier-1 .leadership-team__photo {
    height: 13.5rem;
    width: 13.5rem; }
  .leadership-team__tier-2 .leadership-team__photo {
    height: 10rem;
    width: 10rem; } }

@media screen and (max-width: 61.999em) {
  .leadership-team .member-modal__photo {
    margin-right: 1.5rem;
    margin-top: 0;
    width: 30%;
    padding-bottom: 30%; } }

@media screen and (max-width: 47.999em) {
  .leadership-team__card:not(:last-child) {
    margin-bottom: 1.5rem; } }

.line-cta {
  background: linear-gradient(289deg, #010125 4.1%, #082C4B 119.86%);
  overflow: hidden;
  position: relative; }
  .line-cta__content {
    position: relative;
    z-index: 2; }
  .line-cta__main {
    margin-bottom: 3rem;
    opacity: 0;
    transition: 0.75s opacity; }
    .line-cta__main.in-view {
      opacity: 1; }
  .line-cta__block {
    margin-bottom: 3rem;
    opacity: 0;
    padding: 1rem;
    transform: translate3d(0, 2rem, 0);
    transition: 0.75s opacity, 0.75s transform; }
    .line-cta__block.in-view {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .line-cta__block:last-child {
      margin-bottom: 0; }
    .line-cta__block p, .line-cta__block {
      color: #000000 !important; }
    .line-cta__block--icon {
      height: 3rem;
      width: 3rem;
      margin-bottom: 1rem;
      position: relative; }
      .line-cta__block--icon svg {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .line-cta__block--icon svg {
            height: 100%;
            object-fit: contain;
            object-position: left; } }
    .line-cta__block .arrow-link {
      padding-right: 1.5rem; }
      .line-cta__block .arrow-link:after {
        background-image: url("/icons/arrow-right.svg"); }
  .line-cta__graphic {
    height: 80%;
    position: absolute;
    top: 20%;
    left: 50%;
    width: 150%; }
    .line-cta__graphic svg {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .line-cta__graphic svg {
          height: 100%;
          object-fit: contain;
          object-position: right; } }
  @media screen and (min-width: 48em) {
    .line-cta__content {
      padding-right: 3rem;
      width: 50%; }
    .line-cta__block {
      padding: 1.5rem; }
    .line-cta__graphic {
      height: 70%;
      top: 50%;
      left: auto;
      right: 0;
      transform: translateY(-50%);
      max-width: 45rem;
      min-width: 45rem; }
      .line-cta__graphic--text-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        left: -10%;
        width: 110%; }
      .line-cta__graphic--text {
        animation: revealText .25s ease both;
        line-height: 1.2;
        opacity: .4;
        position: absolute; }
        .line-cta__graphic--text:nth-child(1) {
          animation-delay: 6.2s;
          top: -11%;
          right: 43%;
          transform: rotate(75deg); }
        .line-cta__graphic--text:nth-child(2) {
          animation-delay: 1.35s;
          top: 12%;
          right: 71%;
          transform: rotate(37deg); }
        .line-cta__graphic--text:nth-child(3) {
          animation-delay: 3.4s;
          top: 48.25%;
          right: 81%; }
        .line-cta__graphic--text:nth-child(4) {
          animation-delay: 5.5s;
          top: 85%;
          right: 70%;
          transform: rotate(-39deg); }
        .line-cta__graphic--text:nth-child(5) {
          animation-delay: .75s;
          top: 104%;
          right: 45%;
          transform: rotate(-70deg); }
        .line-cta__graphic--text:nth-child(6) {
          animation-delay: 2.8s;
          top: 104%;
          right: 10%;
          transform: rotate(70deg); }
        .line-cta__graphic--text:nth-child(7), .line-cta__graphic--text:nth-child(8), .line-cta__graphic--text:nth-child(9) {
          display: none; }
        .line-cta__graphic--text:nth-child(10) {
          animation-delay: 4.15s;
          top: -12%;
          right: 8%;
          transform: rotate(-70deg); }
      .line-cta__graphic svg path {
        stroke-dasharray: 7000;
        stroke-dashoffset: 7000;
        animation: drawStroke 8s linear reverse 1 forwards; } }
  @media screen and (min-width: 62em) {
    .line-cta__content {
      padding-right: 6rem; } }

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 0; } }

@keyframes revealText {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

/**
 * Page Header Flex Layout
 * DBS>Interactive
 */
.page-header {
  margin-top: 0 !important;
  overflow: hidden;
  padding-top: 0 !important;
  position: relative; }
  .page-header.bg-dark {
    background: linear-gradient(280deg, #0C1F3E 32.53%, #244577 116.2%); }
  .page-header.bg-dark.video {
    background: linear-gradient(276deg, #11538B 5.27%, #082C4B 95.38%); }
  .page-header > .contain {
    padding-top: 3rem; }
  .page-header__content {
    animation: opacity 1.5s both ease-in;
    margin-bottom: 3rem;
    position: relative;
    z-index: 2; }
    .page-header__content h1:last-child {
      margin-bottom: 0; }
  .page-header__image {
    position: relative;
    text-align: center; }
    .page-header__image svg {
      display: block;
      margin: 0 auto;
      max-height: 20rem;
      max-width: 13rem; }
    .page-header__image.image, .page-header__image.video {
      padding-bottom: 71%;
      height: 0;
      overflow: hidden; }
      .page-header__image.image img,
      .page-header__image.image iframe, .page-header__image.video img,
      .page-header__image.video iframe {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .page-header__image.image img,
          .page-header__image.image iframe, .page-header__image.video img,
          .page-header__image.video iframe {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
    .page-header__image.video {
      padding-bottom: 57%; }
  .page-header__background-text {
    font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
    opacity: .04;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .page-header__background-text[data-length="1"], .page-header__background-text[data-length="2"], .page-header__background-text[data-length="3"], .page-header__background-text[data-length="4"], .page-header__background-text[data-length="5"], .page-header__background-text[data-length="6"] {
      font-size: 27vw; }
    .page-header__background-text[data-length="7"] {
      font-size: 26vw; }
    .page-header__background-text[data-length="8"] {
      font-size: 25vw; }
    .page-header__background-text[data-length="9"] {
      font-size: 24vw; }
    .page-header__background-text[data-length="10"] {
      font-size: 23vw; }
    .page-header__background-text[data-length="11"] {
      font-size: 22vw; }
    .page-header__background-text[data-length="12"] {
      font-size: 21vw; }
    .page-header__background-text[data-length="13"] {
      font-size: 20vw; }
    .page-header__background-text[data-length="14"] {
      font-size: 19vw; }
    .page-header__background-text[data-length="15"] {
      font-size: 18vw; }
    .page-header__background-text[data-length="16"] {
      font-size: 17vw; }
  .page-header:not(.has-bg-text) .page-header__image .page-header__vector-double {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(5%, 0) scale(1.5);
    transform-origin: left top; }
    .page-header:not(.has-bg-text) .page-header__image .page-header__vector-double svg path {
      fill: white;
      filter: blur(4px);
      opacity: .08;
      stroke: white; }
  @media screen and (min-width: 48em) {
    .page-header > .contain {
      padding-top: 4.5rem; }
    .page-header__content {
      margin-bottom: 0;
      padding-bottom: 3rem;
      padding-right: 3rem;
      width: 50%; }
    .page-header__image {
      width: 50%; }
      .page-header__image svg {
        max-width: 23rem; }
      .page-header__image.image {
        padding-bottom: 35.5%; }
      .page-header__image.video {
        padding-bottom: 28.5%; }
    .page-header__background-text[data-length="1"], .page-header__background-text[data-length="2"], .page-header__background-text[data-length="3"], .page-header__background-text[data-length="4"], .page-header__background-text[data-length="5"], .page-header__background-text[data-length="6"] {
      font-size: 15vw; }
    .page-header__background-text[data-length="7"] {
      font-size: 14vw; }
    .page-header__background-text[data-length="8"] {
      font-size: 13vw; }
    .page-header__background-text[data-length="9"] {
      font-size: 12vw; }
    .page-header__background-text[data-length="10"] {
      font-size: 11vw; }
    .page-header__background-text[data-length="11"] {
      font-size: 10vw; }
    .page-header__background-text[data-length="12"] {
      font-size: 9vw; }
    .page-header__background-text[data-length="13"] {
      font-size: 8vw; }
    .page-header__background-text[data-length="14"] {
      font-size: 7vw; }
    .page-header__background-text[data-length="15"] {
      font-size: 6vw; }
    .page-header__background-text[data-length="16"] {
      font-size: 5vw; } }

.partnerships__text {
  margin-bottom: 3rem;
  opacity: 0;
  transition: opacity 0.75s; }
  .partnerships__text.in-view {
    opacity: 1; }

.partnerships__graphic {
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  .partnerships__graphic--outer, .partnerships__graphic--mid, .partnerships__graphic--inner, .partnerships__graphic--center {
    border-radius: 50%;
    border: 1px solid #dadada;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .partnerships__graphic--outer {
    height: 100%;
    width: 100%; }
  .partnerships__graphic--mid {
    height: 75%;
    width: 75%; }
  .partnerships__graphic--inner {
    height: 50%;
    width: 50%; }
  .partnerships__graphic--center {
    height: 25%;
    width: 25%;
    background-color: white;
    border: none;
    box-shadow: 0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.2); }
    .partnerships__graphic--center .partnerships__graphic--icon {
      height: 50%;
      width: 50%;
      transform: translate(-50%, -50%); }
      .partnerships__graphic--center .partnerships__graphic--icon img {
        height: 100% !important;
        width: 100% !important; }
  .partnerships__graphic--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center; }
    .partnerships__graphic--icon img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 10%;
      width: 10%; }
      @supports (object-fit: contain) {
        .partnerships__graphic--icon img {
          height: 100%;
          object-fit: contain;
          object-position: top; } }
    .partnerships__graphic--icon::before {
      background: #ffffff;
      border-radius: 50%;
      content: '';
      height: 16%;
      width: 16%;
      position: absolute;
      top: -3%;
      left: 42%; }
    .partnerships__graphic--icon.outer-ring {
      height: 109%;
      width: 109%;
      z-index: 2; }
      .partnerships__graphic--icon.outer-ring:nth-child(1) {
        animation: circlePathZero 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(1)::before {
          box-shadow: 2px 2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(2) {
        animation: circlePathQuarter 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(2)::before {
          box-shadow: 2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(3) {
        animation: circlePathHalf 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(3)::before {
          box-shadow: -1px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(4) {
        animation: circlePathThreeQuarter 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(4)::before {
          box-shadow: -2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring::before {
        animation: reverseRotate 27s linear infinite; }
    .partnerships__graphic--icon.mid-ring {
      height: 82.5%;
      width: 82.5%; }
      .partnerships__graphic--icon.mid-ring:nth-child(1) {
        animation: circlePathZero 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(1)::before {
          box-shadow: 2px 2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(2) {
        animation: circlePathQuarter 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(2)::before {
          box-shadow: 2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(3) {
        animation: circlePathHalf 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(3)::before {
          box-shadow: -1px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(4) {
        animation: circlePathThreeQuarter 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(4)::before {
          box-shadow: -2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring::before {
        animation: reverseRotate 22s linear infinite; }

@media screen and (min-width: 48em) {
  .partnerships__text {
    margin-bottom: 0;
    padding-right: 4.5rem;
    width: 50%; }
  .partnerships__graphic {
    padding-bottom: 50%;
    width: 50%; } }

@keyframes circlePathZero {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes circlePathQuarter {
  0% {
    transform: translate(-50%, -50%) rotate(90deg); }
  100% {
    transform: translate(-50%, -50%) rotate(450deg); } }

@keyframes circlePathHalf {
  0% {
    transform: translate(-50%, -50%) rotate(180deg); }
  100% {
    transform: translate(-50%, -50%) rotate(540deg); } }

@keyframes circlePathThreeQuarter {
  0% {
    transform: translate(-50%, -50%) rotate(270deg); }
  100% {
    transform: translate(-50%, -50%) rotate(630deg); } }

@keyframes reverseRotate {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes reverseRotateImg {
  0% {
    transform: rotate(360deg) translate(-50%, -50%); }
  100% {
    transform: rotate(0deg) translate(-50%, -50%); } }

/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */
.slider__content {
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: 0.75s opacity; }
  .slider__content.in-view {
    opacity: 1; }

.slider__element {
  box-shadow: none !important;
  position: relative; }
  .slider__element::before {
    background-color: white;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    z-index: 1; }

.slider .flickity-viewport {
  transition: height 0.25s; }

.slider.one-col .slider__content {
  max-width: 45rem; }

.slider.one-col .slider__cell {
  width: 50%; }
  .slider.one-col .slider__cell img {
    display: block; }

.slider.one-col .flickity-button {
  background: #11538B;
  opacity: .5;
  transition: opacity .25s; }
  .slider.one-col .flickity-button:hover, .slider.one-col .flickity-button:focus {
    opacity: 1; }
  .slider.one-col .flickity-button svg path {
    fill: #ffffff; }

.slider.one-col .flickity-viewport {
  overflow: visible; }

.slider.two-col .slider__cell {
  width: 100%; }

.slider.two-col .flickity-button {
  background: #061B3C;
  border-radius: 0;
  height: 7rem;
  width: 2.5rem; }
  .slider.two-col .flickity-button svg {
    left: 25%;
    width: 50%; }
    .slider.two-col .flickity-button svg path {
      fill: #ffffff; }
  .slider.two-col .flickity-button.previous {
    left: 0; }
  .slider.two-col .flickity-button.next {
    right: 0; }

@media screen and (min-width: 48em) {
  .slider.two-col .contain {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .slider.two-col .slider__content {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 35%; }
  .slider.two-col .slider__element {
    width: 65%; } }

@media screen and (min-width: 48em) {
  .slider__cell {
    margin: 0 0.75rem;
    width: calc(25% - 1.5rem); } }

.solution-header {
  position: relative; }
  .solution-header__top {
    background: linear-gradient(276deg, #11538B 5.27%, #082C4B 95.38%);
    padding: 6rem  1.5rem 16rem 0; }
    .solution-header__top--content {
      animation: opacity 1.5s both ease-in;
      position: relative;
      z-index: 2; }
  .solution-header__bottom {
    padding: 16rem  1.25rem 0 0; }
    .solution-header__bottom--content {
      animation: opacity 1.25s 1s both ease-in; }
  .solution-header__graphic {
    background: radial-gradient(103.96% 97.16% at 12.94% 42.52%, #250a0f 0%, #061B3C 71.64%);
    border-radius: 0 0 8rem 0;
    height: 21rem;
    width: 21rem;
    overflow: hidden;
    position: absolute;
    top: 40%;
    right: -20vw;
    transform: rotate(128deg) translateY(-50%); }
    .solution-header__graphic svg {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      transform: translate(-50%, -50%) rotate(-128deg); }
      @supports (object-fit: contain) {
        .solution-header__graphic svg {
          height: 100%;
          object-fit: contain;
          object-position: right; } }
    .solution-header__graphic.fill-graphic svg {
      height: 180%;
      top: 62%;
      left: 62%;
      width: 180%; }
    .solution-header__graphic.contain-graphic svg {
      height: 90%;
      width: 90%; }
    .solution-header__graphic--text-wrapper {
      font-size: .3rem;
      height: 100%;
      position: absolute;
      top: 0;
      left: -10%;
      transform: rotate(-131deg);
      width: 110%; }
    .solution-header__graphic--text {
      animation: revealText .25s ease both;
      color: #ffffff;
      opacity: .4;
      position: absolute; }
      .solution-header__graphic--text:nth-child(1) {
        animation-delay: 6.2s;
        top: -11%;
        right: 43.5%;
        transform: rotate(74deg); }
      .solution-header__graphic--text:nth-child(2) {
        animation-delay: 1.35s;
        top: 19%;
        right: 74%;
        transform: rotate(45deg); }
      .solution-header__graphic--text:nth-child(3) {
        animation-delay: 3.4s;
        top: 50.5%;
        right: 85%; }
      .solution-header__graphic--text:nth-child(4) {
        animation-delay: 5.5s;
        top: 83%;
        right: 77%;
        transform: rotate(-40deg); }
      .solution-header__graphic--text:nth-child(5) {
        animation-delay: .75s;
        top: 101.5%;
        right: 55%;
        transform: rotate(-65deg); }
    .solution-header__graphic svg path {
      stroke-dasharray: 7000;
      stroke-dashoffset: 7000;
      animation: drawStroke 8s linear reverse 1 forwards; }
  @media screen and (min-width: 62em) {
    .solution-header__top {
      padding: 6rem 0; }
      .solution-header__top--content {
        max-width: 35rem; }
    .solution-header__bottom {
      padding: 4.5rem 0 4.5rem; }
      .solution-header__bottom--content {
        max-width: 35rem; }
    .solution-header__graphic {
      height: 47rem;
      bottom: 12%;
      top: auto;
      right: -2%;
      max-height: 47rem;
      max-width: 47rem;
      width: 47rem; }
      .solution-header__graphic--text-wrapper {
        font-size: .75rem; } }

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 0; } }

@keyframes revealText {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

.solutions {
  position: relative; }
  .solutions__block {
    margin-bottom: 6rem; }
  .solutions__text {
    margin-bottom: 3rem; }
  .solutions__image {
    width: 100%; }
    .solutions__image--wrapper {
      height: 0;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative; }
      .solutions__image--wrapper img,
      .solutions__image--wrapper canvas {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .solutions__image--wrapper img,
          .solutions__image--wrapper canvas {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
      .solutions__image--wrapper img {
        opacity: 0; }
    .solutions__image canvas {
      width: 100%; }
  .solutions .solutions-container__wrapper {
    mix-blend-mode: luminosity; }
  @media screen and (max-width: 47.999em) {
    .solutions__text {
      background: #061B3C;
      position: relative;
      z-index: 20; }
      .solutions__text::after {
        background: linear-gradient(to bottom, #061B3C 20%, transparent);
        content: '';
        height: 4rem;
        position: absolute;
        top: 99%;
        width: 100%; }
      .solutions__text::before {
        background: linear-gradient(to top, #061B3C 20%, transparent);
        content: '';
        height: 4rem;
        position: absolute;
        bottom: 99%;
        width: 100%; }
    .solutions-container {
      height: 50vh;
      position: sticky;
      bottom: 0;
      width: 100%; }
    .solutions__block:first-child .solutions__text::before {
      content: none; }
    .solutions .solutions__block:nth-last-of-type(2) .solutions__image {
      height: 0; }
    .solutions__image canvas {
      height: 70vw !important;
      width: 70vw !important; } }
  @media screen and (min-width: 48em) {
    .solutions__text {
      margin-bottom: 6rem;
      padding-right: 3rem;
      width: 50%; }
    .solutions__image {
      width: 50%; }
      .solutions__image--wrapper {
        padding-bottom: 80%;
        position: sticky;
        top: 0;
        transition: .25s opacity; }
        .solutions__image--wrapper img {
          opacity: 0; }
    .solutions__block canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .solutions__block:first-child .solutions__text {
      margin-top: 4.5rem; }
    .solutions-container {
      height: calc(100% - 9rem);
      position: relative;
      width: 50%;
      position: absolute;
      top: 1.5rem;
      left: 50%;
      width: 50%; }
      .solutions-container canvas {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .solutions-container canvas {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
      .solutions-container__wrapper {
        height: 0;
        padding-bottom: 80%;
        position: sticky;
        top: 4.5rem; } }
  @media screen and (min-width: 62em) {
    .solutions__text {
      padding-right: 6rem; } }

.home-hero + .solutions::before {
  background: linear-gradient(to bottom, transparent, #061B3C);
  content: '';
  height: 9rem;
  position: absolute;
  top: -8.9rem;
  left: 0;
  width: 100%;
  z-index: 20; }

.statistic-counter .two-columns__right {
  margin-top: 1.5rem;
  opacity: 1 !important;
  transform: none !important; }

.statistic-counter .statistic-number {
  font-size: 3.8147em;
  line-height: 1; }

@media screen and (min-width: 48em) {
  .statistic-counter .two-columns__right {
    margin-top: 0; }
  .statistic-counter .statistic-number {
    font-size: 5.96046em; } }

.tabbed-content__pane {
  opacity: 0;
  position: absolute;
  visibility: hidden; }
  .tabbed-content__pane--background {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; }
    .tabbed-content__pane--background::before {
      background: linear-gradient(270deg, rgba(17, 83, 139, 0) 0%, #051625 78.01%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1; }
    .tabbed-content__pane--background img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .tabbed-content__pane--background img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .tabbed-content__pane--content {
    padding: 3rem 1.5rem;
    max-width: 40rem;
    position: relative;
    z-index: 2; }
  .tabbed-content__pane.active {
    opacity: 1;
    position: relative;
    visibility: visible; }
  .tabbed-content__pane:first-child .tabbed-content__pane--content {
    opacity: 0;
    transition: opacity 0.75s; }
    .tabbed-content__pane:first-child .tabbed-content__pane--content.in-view {
      opacity: 1; }

.tabbed-content__tab {
  appearance: none;
  background: none;
  border: none;
  border-top: 6px solid transparent;
  border-bottom: 1px solid #dadada;
  padding: 1.5rem 0;
  text-align: left;
  transition: .25s border-color; }
  .tabbed-content__tab:last-child {
    border-bottom: none; }
  .tabbed-content__tab--icon {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    position: relative; }
    .tabbed-content__tab--icon svg,
    .tabbed-content__tab--icon img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .tabbed-content__tab--icon svg,
        .tabbed-content__tab--icon img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .tabbed-content__tab--text h2, .tabbed-content__tab--text h3, .tabbed-content__tab--text h4, .tabbed-content__tab--text h5, .tabbed-content__tab--text h6 {
    color: #11538B;
    font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
    font-weight: 600; }
  .tabbed-content__tab.active, .tabbed-content__tab:focus, .tabbed-content__tab:active {
    border-top-color: #F26525;
    box-shadow: none; }

@media screen and (min-width: 48em) {
  .tabbed-content__pane--content {
    padding: 4.5rem 3rem; }
  .tabbed-content__tab {
    border-bottom: none;
    border-right: 1px solid #dadada;
    padding: 2rem 3rem; }
    .tabbed-content__tab:last-child {
      border-right: none; } }

@media screen and (min-width: 62em) {
  .tabbed-content__pane--content {
    padding: 6rem 3rem; } }

.testimonials {
  background: linear-gradient(180deg, #ffffff 28.62%, transparent 145.52%), #F1F2F2; }
  .testimonials__author {
    text-transform: uppercase; }
  .testimonials__body {
    margin-bottom: 1.5rem; }
  .testimonials__heading {
    margin: 0 auto 1.5rem;
    padding-top: 3rem;
    position: relative;
    text-align: center;
    width: fit-content; }
    .testimonials__heading h1,
    .testimonials__heading h2,
    .testimonials__heading h3,
    .testimonials__heading h4,
    .testimonials__heading h5,
    .testimonials__heading h6 {
      margin-bottom: 0; }
    .testimonials__heading::before {
      height: 1rem;
      width: 1rem;
      background: url("/icons/quotes-bg.svg") no-repeat center;
      background-size: contain;
      content: '';
      height: 6.3rem;
      position: absolute;
      bottom: 0.6rem;
      left: -8.1rem;
      width: 8rem; }
  .testimonials__nav-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 9rem;
    margin-right: 1.5rem;
    width: 8.5rem; }
    .testimonials__nav-button img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
      width: 100%; }
    .testimonials__nav-button.is-selected img {
      height: 8.5rem;
      width: 8.5rem; }
    .testimonials__nav-button.small-icon img {
      height: 5rem !important;
      width: 5rem !important; }
    .testimonials__nav-button:not(.is-selected) {
      filter: blur(2px); }
      .testimonials__nav-button:not(.is-selected) img {
        height: 6.25rem;
        width: 6.25rem; }
  .testimonials__slides {
    box-shadow: none !important; }
  .testimonials__wrapper {
    padding: 1.5rem;
    text-align: center;
    width: 100%; }
  .testimonials .flickity-button.previous {
    left: 0; }
  .testimonials .flickity-button.next {
    right: 0; }
  @media screen and (min-width: 48em) {
    .testimonials__body {
      padding-left: 2.5rem; }
      .testimonials__body:before {
        height: 1.5rem;
        width: 1.5rem; }
    .testimonials__wrapper {
      padding: 3rem; } }

.team-members__heading {
  margin-bottom: 3rem; }

.team-members__item {
  margin: 0 1.5rem 1.5rem;
  width: calc(50% - 3rem); }

.team-members__image {
  border-radius: 50%;
  height: 0;
  margin-bottom: 1.5rem;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .team-members__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .team-members__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.team-members__wrapper {
  margin: 0 -1.5rem -1.5rem; }

@media screen and (min-width: 62em) {
  .team-members__item {
    margin: 0 1.5rem 1.5rem;
    width: calc(25% - 3rem); } }

.two-column-collage .animation-trigger.in-view ~ .two-column-collage__text {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-column-collage__text {
  opacity: 0;
  position: relative;
  margin-bottom: 3rem;
  transition: opacity 0.75s ease-in-out; }

.two-column-collage__collage {
  height: 0;
  padding-bottom: 100%;
  position: relative; }

.two-column-collage__image {
  border: 2px solid white;
  box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.5);
  height: 0;
  opacity: 0;
  position: absolute;
  transition: 0.75s opacity; }
  .two-column-collage__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .two-column-collage__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }
  .two-column-collage__image.in-view {
    opacity: 1; }
  .two-column-collage__image:nth-child(1) {
    top: 5%;
    left: 0;
    padding-bottom: 32%;
    width: 40%; }
  .two-column-collage__image:nth-child(2) {
    top: 0;
    left: 25%;
    padding-bottom: 40%;
    transition-delay: 0.25s;
    width: 60%;
    z-index: 1; }
  .two-column-collage__image:nth-child(3) {
    top: 6%;
    right: 0;
    padding-bottom: 34%;
    transition-delay: 1s;
    width: 40%; }
  .two-column-collage__image:nth-child(4) {
    top: 22%;
    left: 18%;
    padding-bottom: 15%;
    transition-delay: 0.75s;
    width: 20%;
    z-index: 2; }
  .two-column-collage__image:nth-child(5) {
    top: 25%;
    left: 55%;
    padding-bottom: 18%;
    transition-delay: 0.5s;
    width: 28%;
    z-index: 2; }
  .two-column-collage__image:nth-child(6) {
    top: 36%;
    left: 10%;
    padding-bottom: 18%;
    transition-delay: 1.25s;
    width: 25%;
    z-index: 2; }
  .two-column-collage__image:nth-child(7) {
    top: 32%;
    left: 33%;
    padding-bottom: 19%;
    transition-delay: 1.5s;
    width: 30%;
    z-index: 1; }
    .two-column-collage__image:nth-child(7) img {
      object-position: 50% 27%; }
  .two-column-collage__image:nth-child(8) {
    top: 37%;
    left: 62%;
    padding-bottom: 25%;
    transition-delay: 1.75s;
    width: 25%;
    z-index: 1; }
  .two-column-collage__image:nth-child(9) {
    top: 50%;
    left: 15%;
    padding-bottom: 15%;
    transition-delay: 2s;
    width: 25%;
    z-index: 2; }
  .two-column-collage__image:nth-child(10) {
    top: 51%;
    left: 27%;
    padding-bottom: 38%;
    transition-delay: 1.5s;
    width: 63%; }
    .two-column-collage__image:nth-child(10) img {
      object-position: 50% 0%; }

@media screen and (min-width: 48em) {
  .two-column-collage__text {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 50%; }
  .two-column-collage__collage {
    padding-bottom: 50%;
    width: 50%; } }

@media screen and (min-width: 62em) {
  .two-column-collage__text {
    width: 45%; }
  .two-column-collage__collage {
    padding-bottom: 55%;
    width: 55%; } }

.double-slider__slide {
  width: 100%; }

.double-slider__content {
  margin-bottom: 3rem; }

.double-slider__image img {
  display: block; }

.double-slider .flickity-enabled {
  box-shadow: none !important; }

.double-slider .flickity-button {
  background: #061B3C;
  border-radius: 0;
  height: 7rem;
  width: 2.5rem; }
  .double-slider .flickity-button svg {
    left: 25%;
    width: 50%; }
    .double-slider .flickity-button svg path {
      fill: #ffffff; }
  .double-slider .flickity-button.previous {
    left: 0; }
  .double-slider .flickity-button.next {
    right: 0; }

@media screen and (max-width: 47.999em) {
  .double-slider .flickity-button {
    top: auto;
    bottom: 0;
    transform: none; }
    .double-slider .flickity-button.previous {
      left: -1.5rem; }
    .double-slider .flickity-button.next {
      right: -1.5rem; } }

@media screen and (min-width: 48em) {
  .double-slider__content {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 40%; }
  .double-slider__image {
    width: 60%; }
  .double-slider__slide {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .double-slider .flickity-button.previous {
    left: 40%; } }

/**
 * Two Column w/ Blocks Flex Layout
 * DBS Interactive
 */
.two-column-with-blocks .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-column-with-blocks .animation-trigger.in-view ~ .two-column-with-blocks__content,
.two-column-with-blocks .animation-trigger.in-view ~ .two-column-with-blocks__blocks {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-column-with-blocks__content, .two-column-with-blocks__blocks {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-column-with-blocks__content img, .two-column-with-blocks__blocks img {
    display: block; }
  .two-column-with-blocks__content.has-img, .two-column-with-blocks__blocks.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }

.two-column-with-blocks__content {
  transform: translate3d(-2rem, 0, 0); }
  .two-column-with-blocks__content .eyebrow {
    color: #11538B; }

.two-column-with-blocks__block {
  margin-bottom: 1.5rem;
  width: calc(25% - 1.125rem); }
  .two-column-with-blocks__block img {
    margin: auto; }

.two-column-with-blocks__blocks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1.5rem;
  -ms-flex-pack: center;
  justify-content: center;
  height: fit-content;
  transform: translate3d(2rem, 0, 0); }
  .two-column-with-blocks__blocks.count-1, .two-column-with-blocks__blocks.count-2, .two-column-with-blocks__blocks.count-3 {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: auto; }
    .two-column-with-blocks__blocks.count-1 img, .two-column-with-blocks__blocks.count-2 img, .two-column-with-blocks__blocks.count-3 img {
      width: 100%; }
  .two-column-with-blocks__blocks.count-1 .two-column-with-blocks__block {
    width: 100%; }
  .two-column-with-blocks__blocks.count-2 .two-column-with-blocks__block {
    width: calc(50% - 0.75rem); }
  .two-column-with-blocks__blocks.count-3 .two-column-with-blocks__block {
    width: calc(33.33% - 1.5rem); }
  .two-column-with-blocks__blocks.geometric {
    position: relative;
    z-index: -1; }
    .two-column-with-blocks__blocks.geometric .two-column-with-blocks__block {
      z-index: 0; }
    .two-column-with-blocks__blocks.geometric::before {
      background: url("/icons/geometric.svg") center/100% no-repeat;
      content: '';
      height: calc(100% + 20rem);
      opacity: 0.8;
      position: absolute;
      top: calc(50%);
      left: 35%;
      transform: translate(-50%, -50%);
      width: 180%; }
    .two-column-with-blocks__blocks.geometric::after {
      background: linear-gradient(0deg, #fff 0%, transparent 30%, transparent 70%, #fff 100%);
      content: '';
      height: calc(100% + 20rem);
      position: absolute;
      top: calc(50%);
      left: 35%;
      transform: translate(-50%, -50%);
      width: 180%;
      z-index: 1; }

.two-column-with-blocks.content_left .two-column-with-blocks__blocks {
  -ms-flex-order: 1;
  order: 1; }

@media screen and (max-width: 47.999em) {
  .two-column-with-blocks__content {
    margin-bottom: 1.5rem; }
  .two-column-with-blocks.content_left .two-column-with-blocks__blocks {
    margin-bottom: 0; }
  .two-column-with-blocks.content_left .two-column-with-blocks__content {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-column-with-blocks__content:not(.has-img) {
    padding: 1.5rem; }
  .two-column-with-blocks__content, .two-column-with-blocks__blocks {
    width: calc(50% - 1.5rem); }
    .two-column-with-blocks__content.contain-img img, .two-column-with-blocks__blocks.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-blocks__content.contain-img img, .two-column-with-blocks__blocks.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-column-with-blocks__content.cover img, .two-column-with-blocks__blocks.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-blocks__content.cover img, .two-column-with-blocks__blocks.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .two-column-with-blocks__blocks {
    padding: 1.5rem; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-blocks.two-thirds-right .two-column-with-blocks__content {
    width: 38%; } }

@media screen and (min-width: 48em) {
  .two-column-with-blocks.two-thirds-right img {
    height: auto;
    width: 100%; }
  .two-column-with-blocks.two-thirds-right .two-column-with-blocks__blocks.bg-img {
    padding-left: 0; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-blocks.two-thirds-right .two-column-with-blocks__blocks {
    width: calc(62% - 3rem); } }

@media screen and (min-width: 48em) {
  .two-column-with-blocks.two-thirds-left img {
    height: auto;
    width: 100%; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-blocks.two-thirds-left .two-column-with-blocks__content {
    width: calc(62% - 3rem); }
    .two-column-with-blocks.two-thirds-left .two-column-with-blocks__content.bg-img {
      padding-right: 0; }
  .two-column-with-blocks.two-thirds-left .two-column-with-blocks__blocks {
    width: 38%; } }

/**
 * Two Column w/ Testimonial Flex Layout
 * DBS Interactive
 */
.two-column-with-testimonial .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-column-with-testimonial .animation-trigger.in-view ~ .two-column-with-testimonial__content {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-column-with-testimonial .flickity-page-dots {
  bottom: 1.5rem; }
  .two-column-with-testimonial .flickity-page-dots .dot {
    background: #505D68; }

.two-column-with-testimonial__content, .two-column-with-testimonial__testimonial {
  position: relative; }
  .two-column-with-testimonial__content.has-img, .two-column-with-testimonial__testimonial.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .two-column-with-testimonial__content img, .two-column-with-testimonial__testimonial img {
    display: block; }

.two-column-with-testimonial__content {
  opacity: 0;
  transform: translate3d(-2rem, 0, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-column-with-testimonial__content ul {
    list-style-type: none;
    padding-left: 0; }
    .two-column-with-testimonial__content ul li {
      margin-bottom: 1.5rem; }
      .two-column-with-testimonial__content ul li h4 {
        color: #11538B;
        font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
        margin-bottom: .25rem;
        text-transform: none; }
      .two-column-with-testimonial__content ul li p {
        font-size: 0.875rem; }

.two-column-with-testimonial__testimonial {
  padding: 0 !important;
  position: relative;
  transform: translate3d(2rem, 0, 0); }
  .two-column-with-testimonial__testimonial-wrapper {
    background: linear-gradient(275deg, rgba(226, 236, 244, 0.6) 2.35%, rgba(255, 249, 247, 0.6) 77.23%, transparent 100%);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1.5rem;
    overflow: hidden;
    padding: 6rem 3.75rem;
    width: 100%; }
    .two-column-with-testimonial__testimonial-wrapper svg {
      height: 71px;
      margin-bottom: 1.5rem;
      position: relative;
      width: 71px;
      z-index: 1; }
    .two-column-with-testimonial__testimonial-wrapper .quote {
      position: relative; }
      .two-column-with-testimonial__testimonial-wrapper .quote::before {
        background: url("/icons/quotes-bg.svg") no-repeat center;
        content: '';
        height: 6rem;
        left: 50%;
        position: absolute;
        top: -2.3rem;
        transform: translateX(-50%);
        width: 8rem; }
    .two-column-with-testimonial__testimonial-wrapper .title {
      color: #11538B; }
  .two-column-with-testimonial__testimonial.bg-blue-testimonials .flickity-page-dots .dot {
    background: #dadada; }
  .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper {
    background: #11538B !important;
    border-radius: 2rem;
    overflow: hidden; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper p {
      color: #ffffff; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper .quote::before {
      background: url("/icons/quotes-bg-white.svg");
      height: 389px;
      width: 495px;
      top: -10.3rem; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper .title {
      font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
      font-size: 1.375rem; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper.is-selected .quote::before {
      z-index: 3; }

.two-column-with-testimonial.testimonial-left .two-column-with-testimonial__content {
  -ms-flex-order: 1;
  order: 1; }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial .contain {
    padding-left: 1px !important; }
  .two-column-with-testimonial__content:not(.has-img) {
    padding: 1.5rem; }
  .two-column-with-testimonial__content, .two-column-with-testimonial__testimonial {
    width: calc(50% - 1.5rem); }
    .two-column-with-testimonial__content.contain-img img, .two-column-with-testimonial__testimonial.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-testimonial__content.contain-img img, .two-column-with-testimonial__testimonial.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-column-with-testimonial__content.cover img, .two-column-with-testimonial__testimonial.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-testimonial__content.cover img, .two-column-with-testimonial__testimonial.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .two-column-with-testimonial__testimonial {
    height: fit-content;
    padding: 1.5rem; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__content {
    width: 38%; } }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right img {
    height: auto;
    width: 100%; }
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__testimonial.bg-img {
    padding-left: 0; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__testimonial {
    width: calc(62% - 3rem); } }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-left img {
    height: auto;
    width: 100%; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__content {
    width: calc(62% - 3rem); }
    .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__content.bg-img {
      padding-right: 0; }
  .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__testimonial {
    width: 38%; } }

@media screen and (max-width: 47.999em) {
  .two-column-with-testimonial__content {
    margin-bottom: 1.5rem; }
  .two-column-with-testimonial__testimonial {
    margin-left: -2rem;
    width: 100%; }
  .two-column-with-testimonial .two-column-with-testimonial__testimonial-wrapper {
    padding: 3rem 1.5rem 4.5rem; }
  .two-column-with-testimonial.testimonial-left .two-column-with-testimonial__content {
    margin-bottom: 0; }
  .two-column-with-testimonial.testimonial-left .two-column-with-testimonial__testimonial {
    margin-bottom: 1.5rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img, .two-columns__right.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }

.two-columns__left {
  transform: translate3d(-2rem, 0, 0); }

.two-columns__right {
  transform: translate3d(2rem, 0, 0); }

.two-columns .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-columns .animation-trigger.in-view ~ .two-columns__left,
.two-columns .animation-trigger.in-view ~ .two-columns__right {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-columns:not(.full-bleed) .has-img.natural::after {
  border-radius: .75rem; }

.two-columns:not(.full-bleed) .has-img.natural img {
  border-radius: .75rem; }
  @media screen and (min-width: 48em) {
    .two-columns:not(.full-bleed) .has-img.natural img {
      border-radius: 1.375rem; } }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; }
  .two-columns.full-bleed .has-img {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .two-columns + .full-bleed {
    margin-top: 1.5rem !important; } }

@media screen and (min-width: 48em) {
  .two-columns:not(.full-bleed) .has-img.natural::after {
    border-radius: 1.375rem; }
  .two-columns:not(.full-bleed) .has-img.natural img {
    border-radius: .75rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .two-columns:not(.full-bleed) .has-img.natural img {
      border-radius: 1.375rem; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 1.5rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left:not(.has-img), .two-columns__right:not(.has-img) {
      padding: 1.5rem 3rem; }
  .two-columns.full-bleed {
    min-height: 30rem;
    position: relative; }
    .two-columns.full-bleed .contain {
      -ms-flex-align: center;
      align-items: center;
      min-height: 30rem; }
    .two-columns.full-bleed .two-columns__left:not(.has-img),
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      padding: 3rem 0; }
    .two-columns.full-bleed .two-columns__left:not(.has-img) {
      margin-right: auto; }
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      margin-left: auto; }
    .two-columns.full-bleed .has-img {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 50%; }
      .two-columns.full-bleed .has-img.two-columns__left {
        left: 0; }
      .two-columns.full-bleed .has-img.two-columns__right {
        right: 0; }
      .two-columns.full-bleed .has-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .two-columns.full-bleed .has-img img {
            height: 100%;
            object-fit: cover;
            object-position: center; } } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__left {
    width: 38%; } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right img {
    height: auto;
    width: 100%; }
  .two-columns.two-thirds-right .two-columns__right.bg-img {
    padding-left: 0; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: calc(62% - 3rem); } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-left img {
    height: auto;
    width: 100%; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-left .two-columns__left {
    width: calc(62% - 3rem); }
    .two-columns.two-thirds-left .two-columns__left.bg-img {
      padding-right: 0; }
  .two-columns.two-thirds-left .two-columns__right {
    width: 38%; } }

@media screen and (min-width: 62em) {
  .two-columns.full-bleed .two-columns__left:not(.has-img),
  .two-columns.full-bleed .two-columns__right:not(.has-img) {
    padding: 4.5rem 0; } }

/**
 * Collapsible Layout
 * DBS>Interactive
 */
.collapsible {
  margin: 3rem auto; }
  @media screen and (min-width: 48em) {
    .collapsible {
      margin: 4.5rem auto; } }
  .collapsible .collapsible-row {
    overflow: hidden;
    border-top: 1px solid #ffffff; }
    .collapsible .collapsible-row:first-of-type {
      border: none; }
    .collapsible .collapsible-row__header {
      font-size: 1.25em;
      background: #F1F2F2;
      border: none;
      color: #000000;
      cursor: pointer;
      display: block;
      margin-bottom: 0;
      padding: 1rem 4rem 1rem 2rem;
      position: relative;
      text-align: left;
      transition: .3s;
      width: 100%; }
      .collapsible .collapsible-row__header:hover, .collapsible .collapsible-row__header:focus {
        background: #F1F2F2; }
      .collapsible .collapsible-row__header:before, .collapsible .collapsible-row__header:after {
        background-color: #000000;
        border-radius: 10%;
        content: '';
        display: block;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: transform .35s;
        transform: translate(-100%, -50%);
        width: .18rem; }
      .collapsible .collapsible-row__header:before {
        transform: translate(-100%, -50%) rotate(90deg); }
      .collapsible .expanded .collapsible-row__header {
        background: #F1F2F2; }
        .collapsible .expanded .collapsible-row__header:after {
          transform: translate(-100%, -50%) rotate(90deg); }
    .collapsible .collapsible-row__content {
      padding: 2rem; }
      @media screen and (min-width: 48em) {
        .collapsible .collapsible-row__content {
          padding: 2rem 2rem 3rem; } }
    .collapsible .collapsible-row__content--wrapper {
      overflow: hidden;
      transition: max-height .4s ease-in-out, visibility 0s linear .5s; }
      .collapsible .collapsible-row__content--wrapper.loaded {
        max-height: 0; }
      .collapsible .expanded .collapsible-row__content--wrapper {
        max-height: 15em;
        transition: max-height .4s ease-in-out, visibility 0s linear; }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #000000;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * Mixin to create a multi-directional animation with opacity fade.
 *
 * @example: 
 * @include animate3D( fadeRight, -3rem, 0, 0, 0);
 * .slideRight {animation: fadeRight 1s ease 0s 1 both;}
 */
/**
 * Several basic examples are below, but they are being kept commented out
 * to keep unnecessary code from making its way into production. For new animations
 * that involve a "transform: translate", use "translate3d" since it will perform better 
 * because of hardware acceleration.
 */
/**
 * Slide left to right and fade in from invisible 
 */
/**
 * Slide right to left and fade in from invisible 
 */
/**
 * Slide up and fade in from invisible 
 */
/**
 * Slide down and fade in from invisible 
 */
/**
 * Fade in only
 */
/**
 * Bounce
 */
/**
 * Not enough? This site has a lot of great animations with the ability to copy the
 * source code. https://animista.net/play/
 */
@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/**
 * Breadcrumb styles
 * DBS>Interactive
 */
.breadcrumbs {
  font-size: .875rem; }
  .breadcrumbs a,
  .breadcrumbs span {
    color: #000000;
    font-weight: 400;
    text-decoration: none; }
  .breadcrumbs .pipe {
    margin: 0 .25rem; }
  .breadcrumbs-wrapper {
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  @media screen and (min-width: 48em) {
    .breadcrumbs {
      margin-bottom: 3rem; } }

/**
 * Blog Index and Single Post Styles
 * DBS>Interactive
 */
.blogs .post,
.archive .post,
.search .post,
.blog-main .post {
  border-bottom: 1px solid #dadada;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative; }
  .blogs .post:last-of-type,
  .archive .post:last-of-type,
  .search .post:last-of-type,
  .blog-main .post:last-of-type {
    border: none; }
  .blogs .post__content,
  .archive .post__content,
  .search .post__content,
  .blog-main .post__content {
    display: -ms-flexbox;
    display: flex; }
    .blogs .post__content-text,
    .archive .post__content-text,
    .search .post__content-text,
    .blog-main .post__content-text {
      padding-left: 1.5rem; }
  .blogs .post__image,
  .archive .post__image,
  .search .post__image,
  .blog-main .post__image {
    background: #dadada;
    height: 4rem;
    min-width: 6rem;
    overflow: hidden;
    position: relative;
    width: 6rem; }
    .blogs .post__image img,
    .archive .post__image img,
    .search .post__image img,
    .blog-main .post__image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .blogs .post__image img,
        .archive .post__image img,
        .search .post__image img,
        .blog-main .post__image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }

@media screen and (min-width: 62em) {
  .blogs .post__image,
  .archive .post__image,
  .search .post__image,
  .blog-main .post__image {
    height: 8rem;
    min-width: 11rem;
    width: 11rem; } }

.blog-post a:not([class^='button']) {
  color: #11538B; }
  .blog-post a:not([class^='button']):hover {
    color: #176eb8; }

.blog-post .blog-header__image {
  height: 3.25rem;
  margin-right: 1.5rem;
  position: relative;
  width: 3.25rem; }
  .blog-post .blog-header__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 50%; }
    @supports (object-fit: contain) {
      .blog-post .blog-header__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.blog-post .blog-header__text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.5rem; }

.blog-post__featured-text:active, .blog-post__featured-text:focus, .blog-post__featured-text:hover {
  background-color: #fbfbff;
  border-start-end-radius: 1rem;
  border-end-end-radius: 1rem; }

.blog-post__featured:active .blog-post__featured-text, .blog-post__featured:focus .blog-post__featured-text, .blog-post__featured:hover .blog-post__featured-text {
  background-color: #fbfbff; }

.blog-post__featured:active .arrow-link::after, .blog-post__featured:focus .arrow-link::after, .blog-post__featured:hover .arrow-link::after {
  transform: translate(0.5em, -50%); }

.blog-post__footer {
  padding: 1em 0 3em; }
  .blog-post__footer .button-prev.nowhere,
  .blog-post__footer .button-next.nowhere {
    display: none; }

.blog-post__navigation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%; }

.blog-post__related-articles-heading {
  margin-bottom: 3rem; }

.blog-post__related-card {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.1);
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 3rem;
  width: 100%; }
  .blog-post__related-card h3 {
    color: #11538B; }
  .blog-post__related-card .arrow-link.circled {
    margin-top: auto; }

.blog-post__related-image {
  margin-bottom: 0.75rem; }

.blog-post__related-info {
  padding-right: 3rem;
  position: relative; }

.blog-post__related-meta {
  color: #505D68; }
  .blog-post__related-meta .arrow-link.circled-alt {
    position: absolute;
    right: 0;
    top: 50%; }
    .blog-post__related-meta .arrow-link.circled-alt::after {
      height: 3rem;
      width: 3rem; }

.blog-post__related-post {
  padding-bottom: 1.5rem; }
  .blog-post__related-post.first {
    border-bottom: 1px solid #dadada;
    margin-bottom: 1.5rem; }
  .blog-post__related-post:not(.first) .blog-post__related-image {
    margin-right: 0.75rem;
    width: 4.5rem; }
  .blog-post__related-post:not(.first) .blog-post__related-title {
    display: -ms-flexbox;
    display: flex; }
  .blog-post__related-post:active .circled-alt::after, .blog-post__related-post:focus .circled-alt::after, .blog-post__related-post:hover .circled-alt::after {
    transform: translate(0.5em, -50%); }

.blog-post__related-posts-heading {
  color: #505D68;
  font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
  font-weight: 700; }

.blog-post__related-title {
  font-weight: 400;
  margin-bottom: 1.5rem; }
  .blog-post__related-title-text {
    width: calc(100% - 4.5rem); }

.blog-post__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

@media screen and (min-width: 48em) {
  .blog-post .blog-header__text {
    margin-bottom: 0;
    padding-right: 3rem; }
  .blog-post__main {
    width: 70%; }
  .blog-post__related-card {
    width: calc(100% / 3 - 2rem); }
  .blog-post__related-posts {
    padding-left: 2.25rem;
    width: 30%; }
  .blog-post__related-row {
    display: -ms-flexbox;
    display: flex;
    gap: 3rem; }
  .blog-post__wrapper {
    -ms-flex-direction: row;
    flex-direction: row; } }

@media screen and (min-width: 62em) {
  .blog-post__related-posts {
    padding-left: 4.5rem; } }

@media screen and (max-width: 47.999em) {
  .blog-post__main {
    margin-bottom: 4.5rem; }
  .blog-post__related-card:not(:last-child) {
    margin-bottom: 1.5rem; }
  .blog-post__related-posts, .blog-post__related-row {
    margin: 0 auto;
    max-width: 27.25rem; } }

.insights .blog__header {
  position: relative; }
  .insights .blog__header a {
    color: #11538B; }
  .insights .blog__header .search-form {
    border: 0;
    height: 4rem; }
    .insights .blog__header .search-form__button--open {
      background-color: #11538B;
      background-image: url("/icons/search-white.svg");
      background-position: center;
      border-radius: 50%;
      height: 4rem;
      width: 4rem; }
    .insights .blog__header .search-form__form {
      right: 3rem;
      top: 0.4rem; }
  .insights .blog__header .search-form--active .search-form__form {
    overflow: visible; }

.insights .blog-post__featured {
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
  overflow: hidden; }
  .insights .blog-post__featured-image {
    height: 50vw;
    position: relative;
    width: 100%; }
    .insights .blog-post__featured-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .insights .blog-post__featured-image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .insights .blog-post__featured-image.placeholder {
      background: url("/images/site-logo.svg") no-repeat center;
      background-size: 75%;
      background-color: #F1F2F2; }
  .insights .blog-post__featured-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 3rem 1.5rem; }
  .insights .blog-post__featured-title {
    color: #11538B;
    margin-bottom: 1.5rem; }

@media screen and (min-width: 30em) {
  .insights .blog__header .search-form__form {
    padding-left: 1.5rem; } }

@media screen and (min-width: 48em) {
  .insights .blog-post__featured .arrow-link.circled {
    margin-top: auto; }
  .insights .blog-post__featured.featured-left .blog-post__featured-image {
    height: 100%;
    width: 50%; }
  .insights .blog-post__featured.featured-left .blog-post__featured-text {
    width: 50%; }
  .insights .blog-post__featured.featured-left:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3; }
  .insights .blog-post__featured.featured-left:nth-child(2) {
    grid-area: 2 / 1 / 3 / 3; }
  .insights .blog-post__featured.featured-right {
    -ms-flex-direction: column;
    flex-direction: column;
    grid-area: 1 / 3 / 3 / 4; }
    .insights .blog-post__featured.featured-right .blog-post__featured-image {
      height: 50%;
      width: 100%; }
  .insights .blog-post__featured:not(.featured-left):not(.featured-right) .blog-post__featured-image {
    display: none; }
  .insights .blog__wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: (1fr)[2];
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem; } }

@media screen and (max-width: 61.999em) {
  .insights .blog__header .search-form__button--close {
    display: none; } }

@media screen and (max-width: 47.999em) {
  .insights .blog-post__featured {
    -ms-flex-direction: column;
    flex-direction: column; }
    .insights .blog-post__featured:not(:last-child) {
      margin-bottom: 3rem; } }

.legacy-content img,
.legacy-content span,
.legacy-content iframe {
  display: block;
  margin-bottom: 1.5rem; }

.legacy-content h1, .legacy-content h2, .legacy-content h3, .legacy-content h4, .legacy-content h5, .legacy-content h6 {
  line-height: 1.1;
  margin-bottom: 1rem;
  margin-top: 2rem; }

@media screen and (min-width: 48em) {
  .legacy-content img.alignleft, .legacy-content img.alignright {
    width: 40%; } }

@media screen and (min-width: 48em) {
  .blog-header .page-header__content {
    width: 55%; }
  .blog-header .page-header__image {
    padding-bottom: 30%;
    width: 45%; } }

.flex {
  display: -ms-flexbox;
  display: flex; }

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-col-r {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-row-r {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.aic {
  -ms-flex-align: center;
  align-items: center; }

.aie {
  -ms-flex-align: end;
  align-items: flex-end; }

.ais {
  -ms-flex-align: start;
  align-items: flex-start; }

.asc {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.ase {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.ass {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.jcc {
  -ms-flex-pack: center;
  justify-content: center; }

.jce {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.jcsa {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.jcsb {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.jcse {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.fb-25 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%; }

.fb-50 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%; }

.fb-75 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%; }

@media screen and (max-width: 47.999em) {
  .flex-sm {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-sm {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-sm {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-sm {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-sm {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .wrap-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .aic-sm {
    -ms-flex-align: center;
    align-items: center; }
  .aie-sm {
    -ms-flex-align: end;
    align-items: flex-end; }
  .ais-sm {
    -ms-flex-align: start;
    align-items: flex-start; }
  .asc-sm {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-sm {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-sm {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-sm {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-sm {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-sm {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-sm {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .fb-25-sm {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-sm {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-sm {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 48em) {
  .flex-tab {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-tab {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-tab {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-tab {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-tab {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-tab {
    -ms-flex-align: center;
    align-items: center; }
  .aie-tab {
    -ms-flex-align: end;
    align-items: flex-end; }
  .ais-tab {
    -ms-flex-align: start;
    align-items: flex-start; }
  .asc-tab {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-tab {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-tab {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-tab {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-tab {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-tab {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-tab {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-tab {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-tab {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-tab {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-tab {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-tab {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-tab {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 62em) {
  .flex-md {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-md {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-md {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-md {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-md {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-md {
    -ms-flex-align: center;
    align-items: center; }
  .aie-md {
    -ms-flex-align: end;
    align-items: flex-end; }
  .ais-md {
    -ms-flex-align: start;
    align-items: flex-start; }
  .asc-md {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-md {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-md {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-md {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-md {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-md {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-md {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-md {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-md {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-md {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 75em) {
  .flex-lg {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-lg {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-lg {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-lg {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-lg {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-lg {
    -ms-flex-align: center;
    align-items: center; }
  .aie-lg {
    -ms-flex-align: end;
    align-items: flex-end; }
  .ais-lg {
    -ms-flex-align: start;
    align-items: flex-start; }
  .asc-lg {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-lg {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-lg {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-lg {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-lg {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-lg {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-lg {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-lg {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-lg {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-lg {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
.form {
  /**
	* Field Widths/Wrapping
	*/ }
  .form ul {
    list-style: none;
    padding: 0; }
  .form label, .form legend {
    color: #082C4B;
    font-weight: bold; }
  .form__field {
    margin-bottom: 1em; }
  .form .error-text {
    border-bottom: 0.1em solid #F26525;
    color: #F26525;
    padding-bottom: 1em; }
  @media screen and (min-width: 48em) {
    .form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -.75rem; }
      .form__field-wrapper {
        padding: 0 .75rem;
        width: 100%; }
        .form__field-wrapper.width-25 {
          width: 25%; }
        .form__field-wrapper.width-33 {
          width: 33.33%; }
        .form__field-wrapper.width-50 {
          width: 50%; }
        .form__field-wrapper.width-66 {
          width: 66.66%; }
        .form__field-wrapper.width-75 {
          width: 75%; }
      .form .button {
        margin-left: .75rem; } }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=search-input]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.form__field-wrapper select {
  display: block;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin: .333em 0;
  max-width: 100%;
  padding: .75rem;
  width: 100%; }

input[type=search]::-webkit-search-cancel-button {
  cursor: pointer;
  margin-right: 2rem; }

input[type=search].not-empty {
  background-color: #F1F2F2; }

.form__field-wrapper select option {
  margin-bottom: .2rem; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

.form__field-wrapper input[type=checkbox] {
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-right: .5rem; }

.form__field-wrapper input[name="subscribe[]"] + label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: .5rem; }

/**
 * Validation and error message styling
 */
.error-text {
  color: red; }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

/**
 * Pagination Styles
 * DBS>Interactive
 */
.pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
  margin: 1.5rem auto;
  padding: 0;
  text-align: center; }
  .pagination a {
    text-decoration: none; }
  .pagination li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 3rem;
    width: 3rem; }
    .pagination li.active {
      background-color: #505D68;
      color: #ffffff; }
    .pagination li.disabled {
      color: transparent; }
    .pagination li:not(.disabled):active {
      background-color: #F1F2F2; }
    .pagination li:not(.disabled):focus, .pagination li:not(.disabled):hover {
      background-color: #dadada;
      color: #000000; }
  .pagination__link, .pagination__more, .pagination__current,
  .pagination .page-numbers {
    display: inline-block;
    padding: 0.5em 1em;
    text-decoration: none;
    border-radius: 3px;
    transition: .25s ease-in-out; }
  .pagination__link,
  .pagination .page-numbers {
    border: 1px solid #404144;
    font-weight: lighter; }
    .pagination__link:focus, .pagination__link:hover,
    .pagination .page-numbers:focus,
    .pagination .page-numbers:hover {
      background: #404144;
      color: #ffffff; }
  .pagination__current {
    background: #404144;
    border: 1px solid #404144;
    font-weight: bold;
    color: #ffffff; }

/**
 * Table Styles with Responsive Design
 * Based on a CodePen Example by James TW:
 * https://codepen.io/james-tw/pen/GoaXrz
 */
/* Variables */
/**
 * Default styles
 */
table {
  margin-bottom: 1em;
  max-width: 100%;
  width: 100%;
  /**
	 * Responsive desktop styles
	 */
  /**
	 * Mobile only styles
	 */ }
  table tbody {
    display: block; }
  table tr {
    border: 1px solid #e0e0e0;
    display: block;
    margin-bottom: 1rem;
    transition: background-color .25s ease; }
  table th,
  table td {
    border-top: 0;
    padding: 1rem;
    transition: background-color .25s ease;
    text-align: left;
    vertical-align: top; }
  table th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: #757575;
    font-weight: 400;
    vertical-align: bottom; }
  table td {
    display: block;
    text-align: right; }
  table tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    table tfoot td {
      color: #757575;
      font-weight: 400; }
  @media screen and (min-width: 48em) {
    table tbody {
      display: table-row-group; }
    table tr {
      border: none;
      display: table-row; }
    table td {
      display: table-cell;
      text-align: left; } }
  @media screen and (max-width: 47.999em) {
    table thead,
    table tfoot {
      display: none; }
    table td[data-title]:before {
      color: #757575;
      content: attr(data-title);
      float: left;
      font-size: inherit;
      font-weight: 400; }
    table:not(._) td:first-child {
      background-color: #404144;
      border: none;
      color: #ffffff;
      font-weight: 500;
      text-align: left; }
      table:not(._) td:first-child::before {
        display: none; } }

/* Table classes -- Add manually to the table element within the CMS */
/**
 * Bordered table
 *
 * Add horizontal borders between columns.
 */
.table--bordered {
  border: 0; }
  .table--bordered th,
  .table--bordered td {
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (min-width: 48em) {
      .table--bordered th,
      .table--bordered td {
        border: 1px solid #e0e0e0; } }
  .table--bordered thead th,
  .table--bordered thead td {
    border-bottom-width: 2px; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped td:nth-child(odd) {
  background-color: #f5f5f5; }

/**
 * Hover effect styling
 */
@media screen and (min-width: 48em) {
  .table--hover tr:hover {
    cursor: pointer; }
    .table--hover tr:hover td,
    .table--hover tr:hover td:first-child {
      background-color: rgba(0, 0, 0, 0.05); } }

/**
 * Sitemap page styles
 * DBS>Interactive
 */
.sitemap li {
  list-style: none; }
