.careers-feed {
	@include vertical-padding;
	background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), #F1F2F2;

	&__text {
		margin-bottom: 3rem;
		opacity: 0;
		transition: opacity $animationDuration;

		&.in-view {opacity: 1;}
	}

	&__block {
		background: $white;
		margin-bottom: $spacing;
		padding: 1.5rem 1rem;
		width: 100%;

		.h6 {font-size: 1.125rem;}

		.arrow-link {
			padding-right: 1.5rem;
		}
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;

		&--item {
			border-bottom: 1px solid $gray;
			padding: 1.5rem 1rem 1.5rem 1rem;
			position: relative;

			&:last-child {border-bottom: none;}

			.h6 {margin-bottom: .75rem;}

			.arrow-link {
				text-transform: none;
			}
		}
	}

	&__blocks {
		box-shadow: none !important;
	}

	.flickity-button {

		&:disabled {display: none;}

		&.previous {left: -1.5rem;}
		&.next {right: -1.5rem;}

		svg {

			path {fill: $blue;}
		}
	}

	@include media($screen-sm) {

		&__text {
			padding-right: 3rem;
			width: 40%;
		}

		&__blocks {
			gap: $spacing;
		}

		&__block {
			margin-right: 1.5rem;
			margin-bottom: 0;
			width: 33.33%;

			.arrow-link {margin-top: auto;}

			h3 {
				min-height: 2.75rem;
			}
		}

		&__list {

			&--item {
				padding-left: 3rem;

				&::before {
					border-top: 4px solid $orange;
					content: '';
					position: absolute;
						top: 50%;
						left: 1rem;
					width: 1rem;
				}

				.h6 {margin-bottom: 0;}
			}
		}
	}

	@include media($screen-md) {

		&__text {
			margin-bottom: 0;
			padding-right: 2rem;
			width: 35%;
		}

		&__blocks {
			gap: $spacing;
			width: 65%;
		}

		&__list {
			width: 60%;
		}
	}

	&.list-view {
		background: $white;
		padding-bottom: 0;
		padding-top: 0;
	}
}