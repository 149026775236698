.line-cta {
	background: linear-gradient(289deg, #010125 4.1%, #082C4B 119.86%);
	overflow: hidden;
	position: relative;

	&__content {
		position: relative;
		z-index: 2;
	}

	&__main {
		margin-bottom: 3rem;
		opacity: 0;
		transition: $animationDuration opacity;

		&.in-view {opacity: 1;}
	}

	&__block {
		margin-bottom: 3rem;
		opacity: 0;
		padding: 1rem;
		transform: translate3d(0,2rem,0);
		transition: $animationDuration opacity, $animationDuration transform;

		&.in-view {
			opacity: 1;
			transform: translate3d(0,0,0);
		}

		&:last-child {margin-bottom: 0;}

		p, & {color: $textColor !important;}

		&--icon {
			@include size(3rem);
			margin-bottom: 1rem;
			position: relative;

			svg { @include object-fit(contain, left); }
		}

		.arrow-link {
			padding-right: 1.5rem;

			&:after {background-image: url('#{$libraryPath}/icons/arrow-right.svg');}
		}
	}

	&__graphic {
		height: 80%;
		position: absolute;
			top: 20%;
			left: 50%;
		width: 150%;

		svg { @include object-fit (contain, right); }
	}

	@include media($screen-sm) {

		&__content {
			padding-right: 3rem;
			width: 50%;
		}

		&__block {
			padding: 1.5rem;
		}
	
		&__graphic {
			height: 70%;
			top: 50%;
			left: auto;
			right: 0;
			transform: translateY(-50%);
			max-width: 45rem;
			min-width: 45rem;

			&--text-wrapper {
				height: 100%;
				position: absolute;
					top: 0;
					left: -10%;
				width: 110%;
			}

			&--text {
				animation: revealText .25s ease both;
				line-height: 1.2;
				opacity: .4;
				position: absolute;

				&:nth-child(1) {
					animation-delay: 6.2s;
					top: -11%;
					right: 43%;
					transform: rotate(75deg);
				}

				&:nth-child(2) {
					animation-delay: 1.35s;
					top: 12%;
					right: 71%;
					transform: rotate(37deg);
				}

				&:nth-child(3) {
					animation-delay: 3.4s;
					top: 48.25%;
					right: 81%;
				}

				&:nth-child(4) {
					animation-delay: 5.5s;
					top: 85%;
					right: 70%;
					transform: rotate(-39deg);
				}

				&:nth-child(5) {
					animation-delay: .75s;
					top: 104%;
					right: 45%;
					transform: rotate(-70deg);
				}

				&:nth-child(6) {
					animation-delay: 2.8s;
					top: 104%;
					right: 10%;
					transform: rotate(70deg);
				}

				&:nth-child(7),
				&:nth-child(8),
				&:nth-child(9) {display: none;}

				&:nth-child(10) {
					animation-delay: 4.15s;
					top: -12%;
					right: 8%;
					transform: rotate(-70deg);
				}
			}

			$drawTime: 8s;

			svg path {
				stroke-dasharray: 7000;
				stroke-dashoffset: 7000;
				animation: drawStroke $drawTime linear reverse 1 forwards;
			}
		}
	}

	@include media($screen-md) {

		&__content {
			padding-right: 6rem;
		}
	}
}


@keyframes drawStroke {
	0% {
		stroke-dashoffset: 0;
	}
}

@keyframes revealText {
	0% {opacity: .4;}
	100% {opacity: 1;}
}