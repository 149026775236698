/**
 * Two Column w/ Blocks Flex Layout
 * DBS Interactive
 */

.two-column-with-blocks {

    .animation-trigger.in-view~ {
        .has-img {
            opacity: 1;
        }

        .two-column-with-blocks__content,
        .two-column-with-blocks__blocks {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &__content,
    &__blocks {
        opacity: 0;
        position: relative;
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

        img {
            display: block;
        }

        &.has-img {
            opacity: 0;
            transition: opacity $animationDuration ease-in-out;
        }
    }

    &__content {
        transform: translate3d(-2rem, 0, 0);
        
        .eyebrow {
            color: $blue;
        }
    }
    
    &__block {
        margin-bottom: $spacing;
        width: calc(25% - #{$spacing * 3 / 4});
        
        img {
            margin: auto;
        }
    }

    &__blocks {
        display: flex;
            flex-wrap: wrap;
            gap: $spacing;
            justify-content: center;
        height: fit-content;
        transform: translate3d(2rem, 0, 0);
        
        &.count-1,
        &.count-2,
        &.count-3 {
            align-items: center;
            justify-content: space-between;
            height: auto;
            
            img {
                width: 100%;
            }
        }
            
        &.count-1 {
            .two-column-with-blocks__block {
                width: 100%;
            }
        }
        
        &.count-2 {
            .two-column-with-blocks__block {
                width: calc(50% - #{$spacing / 2});
            }
        }
        
        &.count-3 {
            .two-column-with-blocks__block {
                width: calc(33.33% - #{$spacing});
            }
        }
        
        &.geometric {
            position: relative;
            z-index: -1;
            
            .two-column-with-blocks__block {
                z-index: 0;
            }
            
            &::before {
                background: url('/icons/geometric.svg') center/100% no-repeat;
                content: '';
                height: calc(100% + 20rem);
                opacity: 0.8;
                position: absolute;
                    top: calc(50%);
                    left: 35%;
                transform: translate(-50%, -50%);
                width: 180%;
            }
            
            &::after {
                background: linear-gradient(0deg, #fff 0%, transparent 30%, transparent 70%, #fff 100%);
                content: '';
                height: calc(100% + 20rem);
                position: absolute;
                    top: calc(50%);
                    left: 35%;
                transform: translate(-50%, -50%);
                width: 180%;
                z-index: 1;
            }
        }
    }

    &.content_left {
        .two-column-with-blocks__blocks {
            order: 1;
        }
    }

    @include media($screen-sm-max, 'max') {
        &__content {
            margin-bottom: $spacing;
        }
        
        &.content_left {
            .two-column-with-blocks__blocks {
                margin-bottom: 0;
            }
            
            .two-column-with-blocks__content {
                margin-bottom: $spacing;
            }
        }
    }

    @include media($screen-sm) {
        
        &__content {
            &:not(.has-img) {
                padding: $spacing;
            }
        }

        &__content,
        &__blocks {
            width: calc(50% - #{$spacing});

            &.contain-img {
                img {
                    @include object-fit(contain, center);
                }
            }

            &.cover {
                img {
                    @include object-fit(cover, center);
                }
            }
        }
        
        &__blocks {
            padding: $spacing;
        }

        &.two-thirds-right {
            .two-column-with-blocks__content {
                @include media($screen-sm) {
                    width: 38%;
                }
            }

            img {
                // ensures that the ratio effect is not lost by a fixed-width image.
                height: auto;
                width: 100%;
            }

            .two-column-with-blocks__blocks {

                &.bg-img {
                    padding-left: 0;
                }

                @include media($screen-sm) {
                    width: calc(62% - 3rem);
                }
            }
        }

        // Left side larger
        &.two-thirds-left {

            img {
                // ensures that the ratio effect is not lost by a fixed-width image.
                height: auto;
                width: 100%;
            }

            @include media($screen-sm) {
                .two-column-with-blocks__content {
                    width: calc(62% - 3rem);

                    &.bg-img {
                        padding-right: 0;
                    }
                }

                .two-column-with-blocks__blocks {
                    width: 38%;
                }
            }
        }
    }
}