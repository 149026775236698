.case-studies-feed {
    @include transition-delays('.case-studies-feed__card', 20, 0.25s);
    
    .animation-trigger.in-view ~ {
        .case-studies-feed__studies>.case-studies-feed__card,
        .case-studies-feed__heading {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
	}
    
    &__card {
        border-radius: 1rem;
        box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.10);
        opacity: 0;
        overflow: hidden;
		position: relative;
        transform: translate3d(0, 2rem, 0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

        &-body {
            display: flex;
                flex-direction: column;
            height: 100%;
            padding: $spacing;
            
            &:active,
            &:focus,
            &:hover {
                background-color: $whiteDark;
/*                 border-end-start-radius: 1rem;
                border-end-end-radius: 1rem; */
            }
        }

        &-header {
            background: linear-gradient(12deg, rgba(52, 100, 146, 0.34) 8.6%, rgba(9, 20, 59, 0.34) 81.82%), #33628F;
/*             border-start-start-radius: 1rem;
            border-start-end-radius: 1rem; */
            height: 6.66rem;
            padding: $spacing;
            position: relative;
            text-align: center;

            h4 {
                color: $blueDarkest;
                margin-bottom: 0;
                opacity: 0.16;
            }

            svg {
                height: 4rem;
                position: absolute;
                    top: 50%;
                    left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
            }
        }
    }

    &__excerpt {
        font-size: 0.875rem;
        margin-bottom: $spacing;
    }

    &__heading {
        margin-bottom: $spacing;
        opacity: 0;
		position: relative;
        transform: translate3d(0, 2rem, 0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;
    }

    &__link {
        margin-top: auto;
    }
    

    &__title {
        h3 {
            color: $blue;
        }
    }

    @include media($screen-sm) {
        &__card {
            width: calc(33.33% - #{$spacing});
        }

        &__studies {
            display: flex;
                flex-wrap: wrap;
                gap: #{$spacing * 3 / 2};
                justify-content: center;
        }
    }
    
    @include media($screen-md) {
        &__card {
            &-body {
                height: 100%;
            }
            
            &-header {
                height: 7.58rem;
            }
        }
    }

    @include media($screen-sm-max, 'max') {
        &__card:not(:last-child) {
            margin-bottom: $spacing;
        }
    }
}