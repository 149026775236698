.solutions {
	position: relative;

	&__block {
		margin-bottom: 6rem;
	}

	&__text {
		margin-bottom: 3rem;
	}

	&__image {
		width: 100%;

		&--wrapper {
			height: 0;
			overflow: hidden;
			padding-bottom: 100%;
			position: relative;

			img,
			canvas { @include object-fit(contain, center); }

			img {opacity: 0;}
		}

		canvas {
			width: 100%;
		}
	}

	.solutions-container__wrapper {
		mix-blend-mode: luminosity;
	}

	@include media($screen-sm-max, 'max') {

		&__text {
			background: $blueDarkest;
			position: relative;
			z-index: 20;

			&::after {
				background: linear-gradient(to bottom, $blueDarkest 20%, transparent);
				content: '';
				height: 4rem;
				position: absolute;
					top: 99%;
				width: 100%;
			}

			&::before {
				background: linear-gradient(to top, $blueDarkest 20%, transparent);
				content: '';
				height: 4rem;
				position: absolute;
					bottom: 99%;
				width: 100%;
			}
		}

		&-container {
			height: 50vh;
			position: sticky;
				bottom: 0;
			width: 100%;
		}

		&__block:first-child {
			.solutions__text {
				&::before {content: none;}
			}
		}

		.solutions__block:nth-last-of-type(2) {
			.solutions__image {
				height: 0;
			}
		}


		&__image {
			canvas {
				height: 70vw !important;
				width: 70vw !important;
			}
		}
	}

	@include media($screen-sm) {
		

		&__text {
			margin-bottom: 6rem;
			padding-right: 3rem;
			width: 50%;
		}

		&__image {
			width: 50%;

			&--wrapper {
				padding-bottom: 80%;
				position: sticky;
					top: 0;
				transition: .25s opacity;
					
				img {opacity: 0;}
			}
		}

		&__block {

			canvas {
				
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
				
			}

			&:first-child {
				.solutions__text {
					margin-top: 4.5rem;
				}
			}
		}

		&-container {
			height: calc(100% - 9rem);
			position: relative;
			width: 50%;
			position: absolute;
				top: 1.5rem;
				left: 50%;
			width: 50%;

			canvas {
				@include object-fit(contain, center);
			}

			&__wrapper {
				height: 0;
				padding-bottom: 80%;
				position: sticky;
					top: 4.5rem;

				
			}
		}
	}

	@include media($screen-md) {

		&__text {
			padding-right: 6rem;
		}
	}
}

.home-hero + .solutions {

	&::before {
		background: linear-gradient(to bottom, transparent, $blueDarkest);
		content: '';
		height: 9rem;
		position: absolute;
			top: -8.9rem;
			left: 0;
		width: 100%;
		z-index: 20;
	}
}