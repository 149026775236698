.solution-header {
	position: relative;

	&__top {
		background: linear-gradient(276deg, #11538B 5.27%, #082C4B 95.38%);
		padding: 6rem  1.5rem 16rem 0;

		&--content {
			animation: opacity 1.5s both ease-in;
			position: relative;
				z-index: 2;
		}
	}

	&__bottom {
		padding: 16rem  1.25rem 0 0;

		&--content {
			animation: opacity 1.25s 1s both ease-in;
		}
	}

	&__graphic {
		background: radial-gradient(103.96% 97.16% at 12.94% 42.52%, #250a0f 0%, $blueDarkest 71.64%);
		border-radius: 0 0 8rem 0;
		height: 21rem;
		width: 21rem;
		overflow: hidden;
		position: absolute;
			top: 40%;
			right: -20vw;
		transform: rotate(128deg) translateY(-50%);

		svg {
			@include object-fit (contain, right);
			transform: translate(-50%,-50%) rotate(-128deg);
		}

		&.fill-graphic {

			svg {
				height: 180%;
				top: 62%;
				left: 62%;
				width: 180%;
			}
		}

		&.contain-graphic {

			svg {
				height: 90%;
				width: 90%;
			}
		}

		&--text-wrapper {
			font-size: .3rem;
			height: 100%;
			position: absolute;
				top: 0;
				left: -10%;
			transform: rotate(-131deg);
			width: 110%;
		}

		&--text {
			animation: revealText .25s ease both;
			color: $white;
			opacity: .4;
			position: absolute;

			&:nth-child(1) {
				animation-delay: 6.2s;
				top: -11%;
				right: 43.5%;
				transform: rotate(74deg);
			}

			&:nth-child(2) {
				animation-delay: 1.35s;
				top: 19%;
				right: 74%;
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				animation-delay: 3.4s;
				top: 50.5%;
				right: 85%;
			}

			&:nth-child(4) {
				animation-delay: 5.5s;
				top: 83%;
				right: 77%;
				transform: rotate(-40deg);
			}

			&:nth-child(5) {
				animation-delay: .75s;
				top: 101.5%;
				right: 55%;
				transform: rotate(-65deg);
			}
		}

		$drawTime: 8s;

		svg path {
			stroke-dasharray: 7000;
			stroke-dashoffset: 7000;
			animation: drawStroke $drawTime linear reverse 1 forwards;
		}
	}

	@include media($screen-md) {

		&__top {
			padding: 6rem 0;

			&--content {
				max-width: 35rem;
			}
		}

		&__bottom {
			padding: 4.5rem 0 4.5rem;

			&--content {
				max-width: 35rem;
			}
		}

		&__graphic {
			height: 47rem;
			bottom: 12%;
			top: auto;
			right: -2%;
			max-height: 47rem;
			max-width: 47rem;
			width: 47rem;

			&--text-wrapper {
				font-size: .75rem;
			}
		}
	}
}

@keyframes drawStroke {
	0% {
		stroke-dashoffset: 0;
	}
}

@keyframes revealText {
	0% {opacity: .4;}
	100% {opacity: 1;}
}