/**
 * Sitemap page styles
 * DBS>Interactive
 */

.sitemap {

	li {
		list-style: none;
	}
}
