/**
 * Breadcrumb styles
 * DBS>Interactive
 */

.breadcrumbs {
    font-size: .875rem;
    
    a,
    span {
        color: $black;
        font-weight: 400;
        text-decoration: none;
    }
    
    .pipe {
        margin: 0 .25rem;
    }

    &-wrapper {
        background-color: $white;
        border-top: 1px solid rgba(0, 0, 0, 0.20);
        padding-top: #{$spacing / 2};
        padding-bottom: #{$spacing / 2};
    }
    
    @include media($screen-sm) {
        margin-bottom: #{$spacing * 2};
    }
}
