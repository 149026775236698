/**
 * Blog Index and Single Post Styles
 * DBS>Interactive
 */

.blogs,
.archive,
.search,
.blog-main {

    .post {
        border-bottom: 1px solid $gray;
        margin-bottom: $spacing;
        padding-bottom: $spacing;
        position: relative;

        &:last-of-type {
            border: none;
        }

        &__content {
            display: flex;

            &-text {
                padding-left: $spacing;
            }
        }

        &__image {
            background: $gray;
            height: 4rem;
            min-width: 6rem;
            overflow: hidden;
            position: relative;
            width: 6rem;

            img {
                @include object-fit(cover, center);
            }
        }
    }

    @include media($screen-md) {

        .post__image {
            height: 8rem;
            min-width: 11rem;
            width: 11rem;
        }
    }
}

//Single blog posts
.blog-post {

    a:not([class^='button']) {
        color: $primaryColor;

        &:hover {
            color: lighten($primaryColor, 10%);
        }
    }

    .blog-header {
        &__image {
            height: 3.25rem;
            margin-right: $spacing;
            position: relative;
            width: 3.25rem;
            
            img {
                @include object-fit(cover, center);
                border-radius: 50%;
            }
        }

        &__text {
            display: flex;
                align-items: center;
            margin-bottom: $spacing;
        }
    }
    
    &__featured {
        &-text {
            &:active,
            &:focus,
            &:hover {
                background-color: $whiteDark;
                border-start-end-radius: 1rem;
                border-end-end-radius: 1rem;
            }
        }
        
        &:active,
        &:focus,
        &:hover {
            .blog-post__featured-text {
                background-color: $whiteDark;
            }
            
            .arrow-link::after {
                transform: translate(.5em, -50%);
            }
        }
    }

    &__footer {
        padding: 1em 0 3em;

        .button-prev,
        .button-next {
            &.nowhere {
                display: none;
            }
        }
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    &__related {
        &-articles {
            &-heading {
                margin-bottom: #{$spacing * 2};
            }
        }
        
        &-card {
            background-color: $white;
            border-radius: 1rem;
            box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.10);
            display: inline-flex;
                flex-direction: column;
            padding: #{$spacing * 2};
            width: 100%;
            
            h3 {
                color: $blue;
            }
            
            .arrow-link.circled {
                margin-top: auto;
            }
        }
        
        &-image {
            margin-bottom: #{$spacing / 2};
        }
        
        &-info {
            padding-right: 3rem;
            position: relative;
        }
        
        &-meta {
            color: $grayDark;
            
            .arrow-link.circled-alt {
                position: absolute;
                    right: 0;
                    top: 50%;
                    
                &::after {
                    height: 3rem;
                    width: 3rem;
                }
            }
        }
        
        &-post {
            padding-bottom: $spacing;
            
            &.first {
                border-bottom: 1px solid $gray;
                margin-bottom: $spacing;
            }
            
            &:not(.first) {
                .blog-post__related-image {
                    margin-right: #{$spacing / 2};
                    width: 4.5rem;
                }
                
                .blog-post__related-title {
                    display: flex;
                }
            }
            
            // triggers the hover effect on the link when the post is hovered
            &:active,
            &:focus,
            &:hover {
                .circled-alt {
                    &::after {
                        transform: translate(.5em, -50%);
                    }
                }
            }
        }
        
        &-posts {
            &-heading {
                color: $grayDark;
                font-family: $font-montserrat;
                font-weight: 700;
            }
        }
        
        &-title {
            font-weight: 400;
            margin-bottom: $spacing;
            
            &-text {
                width: calc(100% - 4.5rem)
    ;        }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    @include media($screen-sm) {

        .blog-header__text {
            margin-bottom: 0;
            padding-right: $spacing*2;
        }
        
        &__main {
            width: 70%;
        }
        
        &__related {
            &-card {
                width: calc(100% / 3 - #{$spacing * 4 / 3});
            }
            
            &-posts {
                padding-left: #{$spacing * 1.5};
                width: 30%;
            }
            
            &-row {
                display: flex;
                    gap: #{$spacing * 2};
            }
        }

        &__wrapper {
            flex-direction: row;
        }
    }
    
    @include media($screen-md) {
        &__related {
            
            &-posts {
                padding-left: #{$spacing * 3};
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__main {
            margin-bottom: #{$spacing * 3};
        }
        
        &__related {
            &-card {
                &:not(:last-child) {
                    margin-bottom: $spacing;
                }
            }
            
            &-posts,
            &-row {
                margin: 0 auto;
                max-width: 27.25rem;
            }
        }
    }
}

.insights {
    .blog {
        &__header {
            position: relative;
            
            a {
                color: $blue;
            }
            
            .search-form {
                border: 0;
                height: 4rem;
                
                &__button--open {
                    background-color: $blue;
                    background-image: url('/icons/search-white.svg');
                    background-position: center;
                    border-radius: 50%;
                    height: 4rem;
                    width: 4rem;
                }
                
                &__form {
                    right: 3rem;
                    top: 0.4rem;
                }
            }
            
            .search-form--active {                    
                .search-form__form {
                    overflow: visible;
                }
            }
        }
        
        &-post {
            &__featured {
                background: $white;
                border-radius: 1rem;
                box-shadow: 0 .25rem 1.5rem 0 rgba(0, 0, 0, 0.10);
                display: flex;
                overflow: hidden;
                position: relative;
                overflow: hidden;
                
                &-image {
                    height: 50vw;
                    position: relative;
                    width: 100%;
                    
                    img {
                        @include object-fit(cover, center);
                    }

                    &.placeholder {
                        background: url('/images/site-logo.svg') no-repeat center;
                            background-size: 75%;
                        background-color: $grayLighter;
                    }
                }
                
                &-text {
                    display: flex;
                        flex-direction: column;
                    height: 100%;
                    padding: #{$spacing * 2} $spacing;
                }
                
                &-title {
                    color: $blue;
                    margin-bottom: #{$spacing};
                }
            }
        }
    }
    
    @include  media($screen-xs) {
        .blog {
            &__header {
                .search-form__form {
                    padding-left: $spacing;
                }
            }
        }
    }
    
    @include media($screen-sm) {        
        .blog {
            &-post {
                &__featured {
                    .arrow-link.circled {
                        margin-top: auto;
                    }
                    
                    &.featured-left {
                        .blog-post__featured-image {
                            height: 100%;
                            width: 50%;
                        }
                        
                        .blog-post__featured-text {
                            width: 50%;
                        }
                        
                        &:nth-child(1) {
                            grid-area: 1 / 1 / 2 / 3;
                        }
                        
                        &:nth-child(2) {
                            grid-area: 2 / 1 / 3 / 3;
                        }
                    }
                    
                    &.featured-right {
                        flex-direction: column;
                        grid-area: 1 / 3 / 3 / 4;
                        
                        .blog-post__featured-image {
                            height: 50%;
                            width: 100%;
                        }
                    }
                    
                    &:not(.featured-left):not(.featured-right) {
                        .blog-post__featured-image {
                            display: none;
                        }
                    }
                }
            }
            
            &__wrapper {
                display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: $spacing;
                    grid-row-gap: $spacing;
            }
        }
    }
    
    @include media($screen-md-max, 'max') {
        .blog {
            &__header {
                .search-form {
                    &__button--close {
                        display: none;
                    }
                }
            }
        }
    }
            
    @include media($screen-sm-max, 'max') {
        .blog {
            &-post {
                &__featured {
                    flex-direction: column;
                    
                    &:not(:last-child) {
                        margin-bottom: #{$spacing * 2};
                    }
                }
            }
        }
    }
}

.legacy-content {

    img,
    span,
    iframe {
        display: block;
        margin-bottom: $spacing;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.1;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    @include media($screen-sm) {

        img {
            &.alignleft,
            &.alignright {
                width: 40%;
            }
        }
    }
}

@include media($screen-sm) {
    .blog-header {
        .page-header__content {width: 55%;}
        .page-header__image {
            padding-bottom: 30%;
            width: 45%;
        }
    }
}