.team-members {

	&__heading {margin-bottom: $spacing*2;}

	&__item {
		margin: 0 $spacing $spacing;
		width: calc(50% - #{$spacing*2});
	}

	&__image {
		border-radius: 50%;
		height: 0;
		margin-bottom: $spacing;
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;
		
		img {@include object-fit(cover, center);}
	}

	&__wrapper {
		margin: 0 -#{$spacing} -#{$spacing};
	}

	@include media($screen-md) {

		&__item {
			margin: 0 $spacing $spacing;
			width: calc(25% - #{$spacing*2});
		}
	}
}