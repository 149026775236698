/**
 * Pagination Styles
 * DBS>Interactive
 */
.pagination {
    display: flex;
        justify-content: center;
    list-style-type: none;
    margin: $spacing auto;
    padding: 0;
	text-align: center;
    
    a {
        text-decoration: none;
    }
    
    li {
        display: flex;
            align-items: center;
            justify-content: center;
        height: 3rem;
        width: 3rem;
        
        &.active {
            background-color: $grayDark;
            color: $white;
        }
        
        &.disabled {
            color: transparent;
        }
        
        &:not(.disabled) {            
            &:active {
                background-color: $grayLighter;
            }
        
            &:focus,
            &:hover {
                background-color: $gray;
                color: $black;
            }
        }
    }

	&__link,
	&__more,
	&__current,
	.page-numbers {
		display: inline-block;
		padding: 0.5em 1em;
		text-decoration: none;
		border-radius: 3px;
		transition: .25s ease-in-out;
	}

	&__link,
	.page-numbers {
		border: 1px solid $grayDarker;
		font-weight: lighter;
        
		&:focus,
		&:hover {
			background: $grayDarker;
			color: $white;
		}
	}

	&__current {
		background: $grayDarker;
		border: 1px solid $grayDarker;
		font-weight: bold;
		color: $white;
	}
}
