.statistic-counter {

	.two-columns__right {
		margin-top: 1.5rem;
		opacity: 1 !important;
		transform: none !important;
	}

	.statistic-number {
		@include font-scale(6);
		line-height: 1;
	}

	@include media($screen-sm) {

		.two-columns__right {margin-top: 0;}

		.statistic-number {
			@include font-scale(8);
		}
	}
}