.testimonials {
    background: linear-gradient(180deg, $white 28.62%, transparent 145.52%), $grayLighter;

    &__author {
        text-transform: uppercase;
    }
    
    &__body {
        margin-bottom: $spacing;
    }

    &__heading {
        margin: 0 auto $spacing;
        padding-top: #{$spacing * 2};
        position: relative;
        text-align: center;
        width: fit-content;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }
        
        &::before {
            @include size(1rem);
            background: url('#{$libraryPath}/icons/quotes-bg.svg') no-repeat center;
            background-size: contain;
            content: '';
            height: 6.3rem;
            position: absolute;
                bottom: 0.6rem;
                left: -8.1rem;
            width: 8rem;
        }
    }
    
    &__nav {
        &-button {
            display: flex;
                align-items: center;
                justify-content: center;
            height: 9rem;
            margin-right: $spacing;
            width: 8.5rem;
            
            img {
                border-radius: 50%;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            
            &.is-selected {
                img {                    
                    height: 8.5rem;
                    width: 8.5rem;
                }
            }
            
            &.small-icon {
                img {
                    height: 5rem !important;
                    width: 5rem !important;
                }
            }
            
            &:not(.is-selected) {
                filter: blur(2px);
                
                img {                    
                    height: 6.25rem;
                    width: 6.25rem;
                }
            }
        }
    }

    &__slides {
        box-shadow: none !important;
    }

    &__wrapper {
        padding: $spacing;
        text-align: center;
        width: 100%;
    }

    .flickity-button {
        &.previous {
            left: 0;
        }

        &.next {
            right: 0;
        }
    }

    @include media($screen-sm) {
        &__body {
            padding-left: 2.5rem;

            &:before {
                @include size(1.5rem);
            }
        }

        &__wrapper {
            padding: 3rem;
        }
    }
}