/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */

.slider {

    &__content {
        margin-bottom: 3rem;
        position: relative;
        z-index: 2;

        opacity: 0;
		transition: $animationDuration opacity;

		&.in-view {opacity: 1;}
    }

    &__element {
        box-shadow: none !important;
        position: relative;

        &::before {
            background-color: white;
            content: '';
            height: 100%;
            position: absolute;
                top: 0;
                right: 100%;
            width: 100vw;
            z-index: 1;
        }
    }

    .flickity-viewport {
        transition: height 0.25s;
    }

    &.one-col {

        .slider__content {
            max-width: 45rem;
        }

        .slider__cell {
            width: 50%; // Set number of visible slides by width percentage. This is one slide.
    
            img {
                display: block;
            }
        }

        // Nav buttons
        .flickity-button {
            background: $blue;
            opacity: .5;
            transition: opacity .25s;

            &:hover,
            &:focus {
                opacity: 1;
            }

            svg path {fill: $white;}
        }

        .flickity-viewport {overflow: visible;}
    }

    &.two-col {

        .slider__cell {
            width: 100%;
        }

        .flickity-button {
            background: $blueDarkest;
            border-radius: 0;
            height: 7rem;
            width: 2.5rem;

            svg {
                left: 25%;
                width: 50%;

                path {fill: $white;}
            }

            &.previous {left: 0;}
            &.next {right: 0;}
        }

        @include media($screen-sm) {
            .contain {
                display: flex;
                    align-items: center;
            }

            .slider__content {
                margin-bottom: 0;
                padding-right: 3rem;
                width: 35%;
            }

            .slider__element {
                width: 65%
            }
        }
    }

    @include media($screen-sm) {
        &__cell {
            margin: 0 #{$spacing/2};
            width: calc(25% - #{$spacing});
        }
    }
}