.double-slider {

	&__element {

	}

	&__slide {
		width: 100%;
	}

	&__content {
		margin-bottom: 3rem;
	}

	&__image {
		
		img {display: block;}
	}

	.flickity-enabled {box-shadow: none !important;}

	.flickity-button {
		background: $blueDarkest;
		border-radius: 0;
		height: 7rem;
		width: 2.5rem;

		svg {
			left: 25%;
			width: 50%;

			path {fill: $white;}
		}

		&.previous {left: 0;}
		&.next {right: 0;}
	}

	@include media($screen-sm-max, 'max') {

		.flickity-button {
			top: auto;
			bottom: 0;
			transform: none;

			&.previous {left: -1.5rem;}
			&.next {right: -1.5rem;}
		}
	}

	@include media($screen-sm) {

		&__content {
			margin-bottom: 0;
			padding-right: 3rem;
			width: 40%;
		}

		&__image {
			width: 60%;
		}

		&__slide {
			display: flex;
				align-items: center;
		}

		.flickity-button {

			&.previous {
				left: 40%;
			}
		}
	}
}