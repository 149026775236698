.leadership-team {
    .member-modal {
        background: rgba($black, .7);
        display: none;
        height: 100%;
        position: fixed;
            top: 0;
            left: 0;
        width: 100%;
        z-index: 2000;

        &__wrapper {
            background-color: white;
            border-radius: 1rem;
            display: flex;
                flex-direction: column;
            max-height: calc(100% - 3rem);
            max-width: 55rem;
            padding: 1rem;
            position: fixed;
                top: 50%;
                left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 3rem);
            z-index: 2005;
        }


        &__photo {
            background-color: $grayDarkest;
            border: 3px solid $blue;
            border-radius: 50%;
            height: 0;
            margin-bottom: 1.5rem;
            margin-top: -8rem;
            min-height: 8rem;
            min-width: 8rem;
            overflow: hidden;
            padding-bottom: 31%;
            position: relative;
            transform: translate(0, -3rem);
            transition: transform .5s ease-in;
            width: 15.5rem;
                
            img {
                @include object-fit(cover, center);
            }
            
            .leadership-team__placeholder {
                background-color: $grayDarkest;
            }
        }
        
        &__info {
            transform: translate(3rem, 0);
            transition: transform .5s ease-in;
            
            .title {
                background-color: $grayLighter;
                border-radius: .5rem;
                color: $blueDarker;
                font-weight: 400;
                min-height: 0 !important;
                padding: .25rem .5rem
            }
        }
        
        &__header {
            border-bottom: 1px solid $gray;
            display: flex;
                align-items: flex-end;
            padding-bottom: $spacing;
            margin-bottom: $spacing;
        }

        &__close {
            @include size(3rem);
            appearance: none;
            background: url('/icons/src/close-symbol-white.svg') no-repeat right center;
                background-size: 2rem;
                background-color: transparent;
            border: none;
            position: absolute;
                top: 0;
                right: 0;
        }
        
        &.open {
            display: block;
            
            .member-modal__info {
                transform: translate(0, 0);
            }
            
            .member-modal__photo {
                transform: translate(0, 0);
            }
        }

        .title {
            color: #404040;
        }
    }
    
    &__bio {
        display: none;
    }
    
    &__card {
        border: 1px solid $gray;
        border-radius: 1rem;
        cursor: pointer;
        padding: $spacing #{$spacing * 2};
        text-align: center;
        transition: background-color .2s ease;
        
        .arrow-link {
            text-transform: none;
        }
        
        .name {
            color: $blue;
            margin-bottom: 0;
        }
        
        .position {
            font-weight: 400;
        }
        
        &:active,
        &:focus,
        &:hover {
            background-color: $whiteDark;
            
            .arrow-link::after {
                transform: translate(.5em, -50%);
            }
        }
    }
    
    &__modal {
        display: none;
        height: auto;
        position: fixed;
            top: 50%;
            left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        
        &-inner {
            background-color: #fff;
            border-radius: 1rem;
            width: 50%;
            position: relative;
            z-index: 1;
        }
        
        &::after {
            backdrop-filter: blur(1rem);
            background: rgba(0, 0, 0, 0.42);
            content: '';
            position: absolute;
                top: -800%;
                left: -50%;
            height: 100vw;
            width: 100vw;
            z-index: 0;
        }
    }
        
    &__photo {
        height: 10rem;
        margin: 0 auto $spacing;
        position: relative;
        width: 10rem;

        img { 
            @include object-fit(cover, center);
            filter: grayscale(1);
        }
    }
    
    &__placeholder {
        background-color: $white;
        border-radius: 50%;
        display: flex;
        height: 100%;
        width: 100%;
    }
    
    &__tier-1,
    &__tier-2 {
        width: 100%;
    }
    
    &__tier-2 {
        margin-top: $spacing;
    }
    
    @include media($screen-sm) {
        .member-modal {
            
            &__wrapper {
                padding: 3rem;
            }

            &__photo {
                margin-bottom: 0;
                margin-right: 3rem;
            }
    
            &__body {
                width: 60%
            }

            .name {
                margin-bottom: .5rem;
            }

            .title {min-height: 2.5rem;}
        }
        
        &__card {
            display: flex;
                flex-direction: column;

            .arrow-link {
                align-self: center;
                margin-top: auto;
            }
        }
        
        &__photo {
            border: 3px solid $blue;
            border-radius: 50%;
            height: 8rem;
            width: 8rem;
            overflow: hidden;
        }
        
        &__tier-1,
        &__tier-2 {
            display: flex;
                flex-wrap: wrap;
                gap: $spacing;
        }
        
        &__tier-1 {
            .leadership-team__card {
                min-height: 28rem;
                width: calc(100% / 4 - #{$spacing * 2 / 3});
            }
        }
        
        &__tier-2 {
            .leadership-team__card {
                min-height: 20rem;
                padding: $spacing;
                width: calc(100% / 4 - #{$spacing * 3 / 4});
            }
            
            .leadership-team__photo {
                height: 7rem;
                width: 7rem;
            }
        }
    }
    
    @include media($screen-md) {
        &__tier-1 {
            .leadership-team__card {
                padding: $spacing #{$spacing * 2};
            }
            
            .leadership-team__photo {
                height: 13.5rem;
                width: 13.5rem;
            }
        }

        &__tier-2 {
            .leadership-team__photo {
                height: 10rem;
                width: 10rem;
            }
        }
    }
    
    @include media($screen-md-max, 'max') {
        .member-modal {
            &__photo {
                margin-right: $spacing;
                margin-top: 0;
                width: 30%;
                padding-bottom: 30%;
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__card:not(:last-child) {
            margin-bottom: $spacing;
        }
    }
}
