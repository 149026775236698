.tabbed-content {

	&__pane {
		opacity: 0;
		position: absolute;
		visibility: hidden;

		&--background {
			height: 100%;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			z-index: 1;

			&::before {
				background: linear-gradient(270deg, rgba(17, 83, 139, 0.00) 0%, #051625 78.01%);
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
				z-index: 1;
			}

			img { @include object-fit(cover, center); }
		}

		&--content {
			padding: 3rem 1.5rem;
			max-width: 40rem;
			position: relative;
			z-index: 2;
		}

		&.active {
			opacity: 1;
			position: relative;
			visibility: visible;
		}

		&:first-child {
			.tabbed-content__pane--content {
				opacity: 0;
				transition: opacity $animationDuration;

				&.in-view {opacity: 1;}
			}
		}
	}

	&__tab {
		appearance: none;
		background: none;
		border: none;
		border-top: 6px solid transparent;
		border-bottom: 1px solid $gray;
		padding: 1.5rem 0;
		text-align: left;
		transition: .25s border-color;
		
		&:last-child {border-bottom: none}

		&--icon {
			@include size(3rem);
			margin-bottom: 1rem;
			margin-right: 1rem;
			position: relative;

			svg,
			img { @include object-fit(contain, center); }
		}

		&--text {
			h2, h3, h4, h5, h6 {
				color: $blue;
				font-family: $font-montserrat;
				font-weight: 600;
			}
		}

		&.active,
		&:focus,
		&:active {
			border-top-color: $orange;
			box-shadow: none;
		}
	}

	@include media($screen-sm) {

		&__pane {

			&--content {
				padding: 4.5rem 3rem;
			}
		}
	
		&__tab {
			border-bottom: none;
			border-right: 1px solid $gray;
			padding: 2rem 3rem;

			&:last-child {border-right: none}
		}
	}

	@include media($screen-md) {

		&__pane {

			&--content {
				padding: 6rem 3rem;
			}
		}
	}
}