/**
 * Page Header Flex Layout
 * DBS>Interactive
 */

.page-header {
	margin-top: 0 !important;
	overflow: hidden;
    padding-top: 0 !important;
	position: relative;
    
	&.bg-dark {
        background: linear-gradient(280deg, #0C1F3E 32.53%, #244577 116.2%);
	}
    
	&.bg-dark.video {
        background: linear-gradient(276deg, #11538B 5.27%, #082C4B 95.38%);
	}
    
    &>.contain {
        padding-top: 3rem;
    }
    
	&__content {
        animation: opacity 1.5s both ease-in;
		margin-bottom: 3rem;
		position: relative;
		z-index: 2;

		h1:last-child {margin-bottom: 0;}
	}

	&__image {
		position: relative;
        text-align: center;
		
		svg {
			display: block;
			margin: 0 auto;
			max-height: 20rem;
			max-width: 13rem;
		}

		&.image,
		&.video {
			padding-bottom: 71%;
			height: 0;
			overflow: hidden;

			img,
			iframe { @include object-fit(cover, center); }
		}

		&.video {padding-bottom: 57%;}
	}

	&__background-text {
		font-family: $font-bebas-neue;
		opacity: .04;
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%,-50%);

		// Kind of ugly way to dynamically scale text width based on number of characters
		&[data-length="1"],
		&[data-length="2"],
		&[data-length="3"],
		&[data-length="4"],
		&[data-length="5"],
		&[data-length="6"] { font-size: 27vw; }
		&[data-length="7"] { font-size: 26vw; }
		&[data-length="8"] { font-size: 25vw; }
		&[data-length="9"] { font-size: 24vw; }
		&[data-length="10"] { font-size: 23vw; }
		&[data-length="11"] { font-size: 22vw; }
		&[data-length="12"] { font-size: 21vw; }
		&[data-length="13"] { font-size: 20vw; }
		&[data-length="14"] { font-size: 19vw; }
		&[data-length="15"] { font-size: 18vw; }
		&[data-length="16"] { font-size: 17vw; }
	}

	&:not(.has-bg-text) {
		.page-header__image {
			.page-header__vector-double {
				position: absolute;
					top: 0;
					left: 50%;
				transform: translate(5%, 0) scale(1.5);
				transform-origin: left top;

				svg path {
					fill: white;
					filter: blur(4px);
					opacity: .08;
					stroke: white;
				}
			}
		}
	}

	@include media($screen-sm) {

		&>.contain {
			padding-top: 4.5rem;
		}

		&__content {
			margin-bottom: 0;
			padding-bottom: 3rem;
			padding-right: 3rem;
			width: 50%;
		}

		&__image {
			width: 50%;

			svg {max-width: 23rem;}

			&.image {padding-bottom: 35.5%;}
			&.video {padding-bottom: 28.5%;}
		}

		&__background-text {

			&[data-length="1"],
			&[data-length="2"],
			&[data-length="3"],
			&[data-length="4"],
			&[data-length="5"],
			&[data-length="6"] { font-size: 15vw; }
			&[data-length="7"] { font-size: 14vw; }
			&[data-length="8"] { font-size: 13vw; }
			&[data-length="9"] { font-size: 12vw; }
			&[data-length="10"] { font-size: 11vw; }
			&[data-length="11"] { font-size: 10vw; }
			&[data-length="12"] { font-size: 9vw; }
			&[data-length="13"] { font-size: 8vw; }
			&[data-length="14"] { font-size: 7vw; }
			&[data-length="15"] { font-size: 6vw; }
			&[data-length="16"] { font-size: 5vw; }
		}
	}
}
