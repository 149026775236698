.home-hero {
	background: linear-gradient(289deg, #010125 4.1%, #082C4B 119.86%);
	overflow: hidden;
	position: relative;

	&__text,
	&__graphic {
		z-index: 3;
	}

	&__text {
		animation: opacity 1.5s both ease-in;
		padding: 3rem 0;
		position: relative;
		z-index: 2000;
	}

	&__graphic {
		height: 100%;
		mix-blend-mode: luminosity;
		position: absolute;
			top: 50%;
			left: 85%;
		transform: translateY(-50%) rotate(180deg);
		width: 150%;

		svg { @include object-fit(contain, center); }

		&.rotate canvas {
			animation: rotate 60s linear infinite forwards;
			transform-origin: center;
		}
	}

	.home-hero__line {
		height: 100%;
		pointer-events: none;
		position: absolute;
			top: 0;

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}

		&.line-1 {
			background: rgba(40, 22, 111, 0.10);
			left: 5vw;
			width: 34%;
		}

		&.line-2 {
			background: linear-gradient(90deg, #09143C 0%, rgba(5, 20, 54, 0.00) 100%);
			left: calc(34% + 5vw);
			width: calc(66% - 10vw);

			&::before {
				left: 50%;
			}
		}

		@include media($screen-sm-max, 'max') {

			&.line-1 {
				width: 80%;
			}

			&.line-2 {
				display: none;
			}
		}
	}

	@include media($screen-sm) {

		&__text {
			padding: 7.5rem 0;
			width: 70%;
		}

		&__graphic {
			left: auto;
			right: -3rem;
			width: 50%;
		}
	}

	@include media($screen-md-max, 'max') {

		&__graphic {
			left: auto;
			right: -30%;
			width: 8%;

			canvas {
				height: 100% !important;
				object-fit: contain;
				object-position: center;
				position: absolute;
					top: 0;
					left: 0;
				width: 100% !important;
			}

			&:not(.rotate) {
				canvas {
					object-fit: cover;
					object-position: right;
				}
			}
		}
	}

	@include media($screen-md) {

		&__text {
			padding-right: 3rem;
			width: 45%;
		}

		&__graphic {
			left: -5%;
			width: 105%;
		}
	}
}

@keyframes rotate {
	0% {transform: rotate3d(0,0,1,0deg);}
	100% {transform: rotate3d(0,0,1,-360deg);}
}