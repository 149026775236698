/**
 * Full Width Flex Layout
 * DBS>Interactive
 *
 * This layout takes up the full width of the browser and may have a background image.
 */

.full-width {
	.contain>* {
        opacity: 0;
		position: relative;
        transform: translate3d(0,2rem,0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;
    }
    
    .animation-trigger.in-view ~ {
		* {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
	}

    .animation-trigger {position: absolute !important;}
}
